import React from "react"
import horizenLogo from "../../assets/horizen_logo.png"
import horizenLabsLogo from "../../assets/horizenlabs_logo.png"
import SolidButton from "../SolidButton";

const HomeSidebar: React.FC = () => {

    const titleAndLogo = (title: string, logo: string, altText: string) => {
        return (
                <>
                    <div className="flex">
                        <p className="text-Gray_text flex text-base font-bold mb-4 mt-6">
                            { title }
                        </p>
                    </div>
                    <img
                            className="mb-12 h-8 object-contain"
                            src={ logo }
                            alt={ altText }
                    />
                </>
        )
    }

    return (
            <div className="bg-Main_bckgrnd px-8 mt-20 xl:mr-8 xl:ml-0 mx-8">
                <h2 className="font-bold text-white text-4xl mb-4">About TokenMint</h2>
                <p className="text-Content_gray text-lg leading-6">
                    TokenMint by Horizen is the platform that enables anyone in the world to easily create and mint
                    their own utility tokens.<br/><br/> The platform is powered by the TokenMint ledger which serves as
                    a transparent, tamper-proof store of the history of the token’s transactions.
                </p>
                <div className="mx-auto mt-6 mb-7 flex">
                    <SolidButton label="LAUNCH A TOKEN"
                                 href={ process.env.REACT_APP_TOKENMINT_URL }
                                 openNewTab={ true }
                    />
                </div>
                <div className="grid grid-cols-2 space-x-6">
                    <div className="col-span-1">
                        { titleAndLogo("Developed by", horizenLabsLogo, "HorizenLabs Logo") }
                    </div>
                    <div className="col-span-1">
                        { titleAndLogo("Powered by", horizenLogo, "Horizen Logo") }
                    </div>
                </div>
            </div>
    )
}

export default HomeSidebar
