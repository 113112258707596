import React from "react"
import TokenMintSpinner from "../../templates/TokenMintSpinner";

export type ListContainerProps = {
    titleSection: any
    elements: Array<string>
    elementsConfig: Array<string>
    isLoading: boolean
    isEmpty: boolean
    tableContent?: any
    children?: any
    customClass?: string
}

const ListContainer: React.FC<ListContainerProps> = (
        {
            titleSection,
            elements,
            elementsConfig,
            isLoading,
            isEmpty,
            tableContent,
            children,
            customClass
        }
) => {

    const headerContent = () => {
        if (elements.length === 0) {
            throw Error("No elements to add to header")
        }
        if (elements.length !== elementsConfig.length) {
            throw Error("Elements and config must have the same length")
        }
        return elements.map((elem, index) => {
            return (
                    <th
                            data-testid={ `header_${ elem.toLocaleLowerCase() }` }
                            key={ `key_${ elem }` }
                            className={ (index === 0 ? "" : "")
                                    .concat(
                                            index === elements.length - 1
                                                    ? "text-right"
                                                    : "text-left"
                                    )
                                    .concat(" text-Gray_text tracking-wider text-xs font-bold w-")
                                    .concat(elementsConfig[index]) }
                    >
                        { elem.toUpperCase() }
                    </th>
            )
        })
    }

    return (
            <div className={ customClass ?? "" }>
                { titleSection }
                <div className={ `${ customClass ?? "" } border border-white px-8 py-4 overflow-y-auto` }>
                    <div data-testid={ "list_content_container" }
                         className={ `${ customClass ?? "" } ` }>
                        { tableContent && (
                                <table className="w-full table-fixed">
                                    { isEmpty && (
                                            <p className="text-center text-Gray_text text-xl my-4"
                                               data-testid={ "empty_list_container" }>
                                                No elements found
                                            </p>
                                    ) }
                                    { !isEmpty && (
                                            <>
                                                <thead className="border-b border-Gray_text">
                                                <tr
                                                        data-testid="header"
                                                        className="h-10 text-gray-light"
                                                >
                                                    { headerContent() }
                                                </tr>
                                                </thead>
                                                { tableContent }
                                            </>
                                    ) }
                                </table>
                        ) }
                        { isLoading && (
                                <div className="w-14 my-6 mx-auto">
                                    <TokenMintSpinner/>
                                </div>
                        ) }
                        { children }
                    </div>
                </div>
            </div>
    )
}

export default ListContainer
