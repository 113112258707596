import React, { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { Link, useParams } from "react-router-dom"
import { getNFTCollections } from "../network/APIRepository"
import { SCAPI } from "../network/explorer_types/SCAPI"
import { URLProvider } from "../utils/URLProvider"
import { bigNumberFormat } from "../utils/Utils"
import ListHeader from "../components/ListHeader/ListHeader";
import ListContainer from "../components/ListContainer/ListContainer";

const NFTCollections: React.FC = () => {
    const params = useParams()
    const {tokenSearch} = params

    const [currentPage, setCurrentPage] = useState<number>(0)
    const [totalPages, setTotalPages] = useState<number | undefined>(undefined)
    const [totalTokens, setTotalTokens] = useState<string | undefined>(undefined)
    const {
        data: collectionsResponse,
        error,
        isLoading
    } = useQuery<[Array<SCAPI.TokenBoxType>, number] | void>(["getCollections", currentPage, tokenSearch], () => getNFTCollections(currentPage, tokenSearch))

    useEffect(() => {
        if (collectionsResponse) {
            setTotalPages(collectionsResponse[1])
            if (currentPage + 1 === collectionsResponse[1]) {
                // If last page we calculate with the number of pages and last items in last page
                setTotalTokens((collectionsResponse[0].length + currentPage * 20).toString())
            } else if (collectionsResponse[1] === 0) {
                setTotalTokens("0")
            } else {
                // Otherwise, we estimate from the number of pages
                const currentNumber = (Number(collectionsResponse[1]) - 1) * 20
                if (totalTokens) {
                    if (Number(totalTokens) < currentNumber) {
                        setTotalTokens(currentNumber.toString().concat("+"))
                    }
                } else {
                    setTotalTokens(currentNumber.toString().concat("+"))
                }
            }
        }

    }, [collectionsResponse])

    const titleSection = () => {
        return (
                <ListHeader
                        title="TokenMint NFT Collections"
                        secondaryTitle={ collectionsResponse ? `${ totalTokens } collections found` : "" }
                        currentPage={ currentPage + 1 }
                        totalPages={ collectionsResponse ? collectionsResponse[1] === 0 ? 1 : collectionsResponse[1] : undefined }
                        hasPreviousPage={ currentPage > 0 }
                        onPreviousClick={ () => {
                            setCurrentPage(currentPage - 1)
                        } }
                        onFirstClick={ () => {
                            if (currentPage === 0) {
                                return
                            }

                            setCurrentPage(0)
                        } }
                        onLastClick={ () => {
                            if ((totalPages && currentPage === totalPages - 1) ||
                                    totalPages === 0) {
                                return
                            }

                            setCurrentPage(totalPages! - 1)
                        } }
                        hasNextPage={ totalPages ? totalPages > currentPage + 1 : false }
                        onNextClick={ () => {
                            setCurrentPage(currentPage + 1)
                        } }/>
        )
    }

    return (
            <div>
                <main>
                    <div className="max-w-screen-xl ml-auto mr-auto xl:grid">
                        <div className="px-8 sm:pb-8 space-y-16 mt-20">
                            <ListContainer
                                    titleSection={ titleSection() }
                                    elements={ ["Collection", "Circulating Supply", "Total Supply", "UUID"] }
                                    elementsConfig={ ["1/4", "1/4", "1/4", "1/4"] }
                                    isLoading={ isLoading }
                                    isEmpty={ collectionsResponse ? collectionsResponse[0].length === 0 : false }
                                    tableContent={
                                        collectionsResponse ? collectionsResponse[0].map((b: SCAPI.TokenBoxType, index: number) => {
                                            return (
                                                    <tr key={ b.uuid }
                                                        className={ (index === collectionsResponse[0].length - 1 ? "" : "border-b").concat(" border-Gray_text h-12") }>
                                                        <td className="text-sm pr-8 truncate">
                                                            <Link
                                                                    className="hover:underline text-white hover:text-ZBF_green font-bold"
                                                                    to={ URLProvider.URL_COLLECTION_DETAIL.replace(
                                                                            ":collectionId",
                                                                            b.uuid
                                                                    ) }
                                                            >
                                                                { `${ b.name } (${ b.symbol })` }
                                                            </Link>
                                                        </td>
                                                        <td className="text-sm pr-8 text-white truncate"
                                                            title={ bigNumberFormat(b.currentSupply, b.precision) }>{ bigNumberFormat(b.currentSupply, b.precision) }</td>
                                                        <td className="text-sm pr-8 text-white truncate"
                                                            title={ bigNumberFormat(b.maxSupply, b.precision) }>{ bigNumberFormat(b.maxSupply, b.precision) }</td>
                                                        <td className="text-sm text-right text-white truncate"
                                                            title={ b.uuid }>{ b.uuid }</td>
                                                    </tr>
                                            )
                                        }) : undefined
                                    }
                            >
                            </ListContainer>
                        </div>
                    </div>
                </main>
            </div>
    )
}

export default NFTCollections
