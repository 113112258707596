import React, { useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import TokenMintSpinner from "../templates/TokenMintSpinner";
import NoImageIcon from "../assets/no-image-placeholder.png";
import { URLProvider } from "../utils/URLProvider";
import { SCAPI } from "../network/explorer_types/SCAPI";
import { getIPFSFinalURL } from "../utils/Utils";

export type NFTItemProps = {
    baseURI: string
    collectionSymbol: string
    nft: SCAPI.TokenNonFungibleBoxType
}

const NFTItem: React.FC<NFTItemProps> = (
        {
            baseURI,
            collectionSymbol,
            nft,
        }
) => {

    const [imageURI, setImageURI] = useState<string | undefined>(undefined)
    const [imageIsLoading, setImageIsLoading] = useState(true);
    const [imageError, setImageError] = useState(false);

    const {
        data,
        error,
        isLoading: metadataIsLoading
    } = useQuery<[any[], number] | void>(
            ["getNFTMetadata", baseURI, nft.serialNumber],
            async () => {
                const axiosInstance = axios.create();
                await axiosInstance
                        .get(`${ baseURI }${ nft.serialNumber }`, {})
                        .then((result) => {
                            if (result.data.image && result.data.image !== "") {
                                const finalURI = getIPFSFinalURL(result.data.image);
                                setImageURI(finalURI);
                            } else {
                                setImageError(true)
                                setImageIsLoading(false);
                            }
                        })
                        .catch((e) => {
                            setImageError(true)
                            setImageIsLoading(false);
                        });
            }
    )

    return (
            <div className="grid mx-4">
                <>
                    { imageURI && (
                            <a href={ URLProvider.URL_NFT_DETAIL.replace(":collectionId", (nft as any).uuid).replace(":serialNumber", nft.serialNumber) }>
                                <img className={ `object-contain aspect-square ${ imageIsLoading ? "hidden" : "block" }` }
                                     alt={ "NFT image" }
                                     onLoad={ () => {
                                         setImageIsLoading(false);
                                     } }
                                     onError={ () => {
                                         setImageIsLoading(false);
                                     } }
                                     src={ imageURI }/>
                            </a>
                    ) }
                    { (error || imageError) && (
                            <a href={ URLProvider.URL_NFT_DETAIL.replace(":collectionId", (nft as any).uuid).replace(":serialNumber", nft.serialNumber) }>
                                <img className="aspect-square"
                                     alt={ "Image not found placeholder" }
                                     src={ NoImageIcon }/>
                            </a>
                    ) }
                    { (metadataIsLoading || imageIsLoading) && (
                            <div className="aspect-square p-20">
                                <TokenMintSpinner/>
                            </div>
                    ) }
                </>
                <p className="mt-2 text-sm text-white font-bold truncate">{ `${ collectionSymbol } #${ nft.serialNumber }` }</p>
            </div>
    )
}

export default NFTItem