import React, { useEffect, useState } from "react"
import Calendar from "react-calendar"
import "react-calendar/dist/Calendar.css"
import Modal from "react-modal"
import { Link, useNavigate, useParams } from "react-router-dom"
import ListContainer from "../../components/ListContainer/ListContainer"
import ListHeader from "../../components/ListHeader/ListHeader"
import arrowCollapse from "../../assets/green_arrow_left.png"
import { getTransactions } from "../../network/APIRepository"
import { SCAPI } from "../../network/explorer_types/SCAPI"
import { TransactionJSON } from "../../network/explorer_types/Transaction"
import { Constants } from "../../utils/Constants"
import { RequestState } from "../../utils/RequestState"
import { URLProvider } from "../../utils/URLProvider"
import {
    bigNumberFormat,
    DateFormat,
    getDateFromString,
    getFormattedDate,
    getNextDate,
    getPreviousDate,
    txDateToShow
} from "../../utils/Utils"
import "../Calendar.css"

const Transactions: React.FC = () => {

    const navigate = useNavigate()
    const params = useParams()
    const {txDate} = params

    const [calendarCoordinates, setCalendarCoordinates] = useState<{ x: number; y: number } | undefined>(undefined)
    const [calendarModal, setCalendarModal] = useState(false)
    const [calendarOpen, setCalendarOpen] = useState(false)
    const [currentDate, setCurrentDate] = useState<Date | undefined>()
    const [currentPage, setCurrentPage] = useState<number>(0)
    const [totalPages, setTotalPages] = useState<number | undefined>(undefined)
    const [needsReload, setNeedsReload] = useState(false)

    const [txsRequestState, setTxsRequestState] = useState<RequestState>(
            RequestState.NOT_STARTED
    )
    const [txs, setTxs] = useState<Array<SCAPI.Transaction & TransactionJSON> | undefined>(
            undefined
    )

    const getBlocksWithLimit = (timestamp?: number) => {
        setTxsRequestState(RequestState.LOADING)
        getTransactions(false, currentDate, currentPage, undefined)
                .then((result) => {
                    if (result) {
                        const currentBlocks = txs ? [...txs] : []
                        setTxs([...currentBlocks, ...result[0]])
                        setTotalPages(result[1])
                    }
                })
                .catch((error) => {
                    // TODO: Show error
                })
                .finally(() => {
                    setTxsRequestState(RequestState.DONE)
                    setNeedsReload(false)
                })
    }

    useEffect(() => {
        if (!currentDate) {
            if (txDate) {
                setCurrentDate(getDateFromString(txDate))
            } else {
                setCurrentDate(new Date())
            }
            setCurrentPage(0)
            setTxs(undefined)
            setNeedsReload(true)
        }
    }, [])

    useEffect(() => {
        setNeedsReload(true)
    }, [currentDate])

    useEffect(() => {
        if (needsReload && txsRequestState !== RequestState.LOADING) {
            setNeedsReload(false)
            getBlocksWithLimit()
        }
    }, [needsReload])

    const titleSection = () => {
        return (
                <ListHeader
                        title="Transactions"
                        currentPage={ currentPage + 1 }
                        totalPages={ totalPages === 0 ? 1 : totalPages }
                        currentCalendarDate={ currentDate }
                        onCalendarClicked={ (coordinates) => {
                            if (window.innerWidth >= 1024) {
                                // If we are on desktop mode, calendar is shown next to the button
                                setCalendarModal(false)
                                setCalendarCoordinates({
                                    x: coordinates[0],
                                    y: coordinates[1]
                                })
                            } else {
                                // Otherwise, we use a modal
                                setCalendarModal(true)
                                setCalendarCoordinates(undefined)
                            }

                            setCalendarOpen(!calendarOpen)
                        } }
                        onCalendarPreviousDateSelected={ () => {
                            setCurrentPage(0)
                            setTotalPages(undefined)
                            setTxs(undefined)
                            const previousDayDate = new Date(currentDate!)
                            previousDayDate.setDate(currentDate!.getDate() - 1)
                            setCurrentDate(previousDayDate)
                            navigate(
                                    URLProvider.URL_TRANSACTIONS_DATE.replace(
                                            ":txDate",
                                            getPreviousDate(currentDate!)
                                    )
                            )
                        } }
                        onCalendarNextDateSelected={ () => {
                            setCurrentPage(0)
                            setTotalPages(undefined)
                            setTxs(undefined)
                            const nextDayDate = new Date(currentDate!)
                            nextDayDate.setDate(currentDate!.getDate() + 1)
                            setCurrentDate(nextDayDate)
                            navigate(
                                    URLProvider.URL_TRANSACTIONS_DATE.replace(
                                            ":txDate",
                                            getNextDate(currentDate!)
                                    )
                            )
                        } }
                        hasPreviousPage={ currentPage > 0 }
                        onPreviousClick={ () => {
                            setCurrentPage(currentPage - 1)
                            setTxs(undefined)
                            setNeedsReload(true)
                        } }
                        onFirstClick={ () => {
                            setCurrentPage(0)
                            setTxs(undefined)
                            setNeedsReload(true)
                        } }
                        onLastClick={ () => {
                            if ((totalPages && currentPage === totalPages - 1) ||
                                    totalPages === 0) {
                                return
                            }

                            setCurrentPage(totalPages! - 1)
                            setTxs(undefined)
                            setNeedsReload(true)
                        } }
                        hasNextPage={ totalPages ? totalPages > currentPage + 1 : false }
                        onNextClick={ () => {
                            if ((totalPages && currentPage === totalPages - 1) ||
                                    totalPages === 0) {
                                return
                            }

                            setCurrentPage(currentPage + 1)
                            setTxs(undefined)
                            setNeedsReload(true)
                        } }/>
        )
    }

    const calendarComponent = (isModal: boolean) => {
        const componentClassName = isModal
                ? `${
                        calendarOpen ? "block" : "hidden"
                } mx-auto my-auto text-center relative top-[calc(35vh)]`
                : `${ calendarOpen ? "block" : "hidden" } mx-auto my-auto text-center`

        return (
                <Calendar
                        prevLabel={ <img className="h-4 mx-auto" src={ arrowCollapse }/> }
                        prev2Label={ <div className="inline-flex mt-0.5"><img className="h-4"
                                                                              src={ arrowCollapse }/><img
                                className="h-4" src={ arrowCollapse }/></div> }
                        nextLabel={ <img className="h-4 rotate-180 mx-auto" src={ arrowCollapse }/> }
                        next2Label={ <div className="inline-flex mt-0.5"><img className="h-4 rotate-180"
                                                                              src={ arrowCollapse }/><img
                                className="h-4 rotate-180" src={ arrowCollapse }/></div> }
                        maxDate={ new Date() }
                        className={ componentClassName }
                        onChange={ (date: Date) => {
                            setTxs(undefined)
                            setCalendarOpen(false)
                            setCurrentPage(0)
                            setCurrentDate(date)
                            navigate(
                                    URLProvider.URL_TRANSACTIONS_DATE.replace(
                                            ":txDate",
                                            getFormattedDate(date, DateFormat.yyyymmdd)
                                    )
                            )
                        } }
                        value={ currentDate }
                />
        )
    }

    return (
            <div>
                <main>
                    <div className="max-w-screen-xl ml-auto mr-auto xl:grid">
                        <div className="px-8 sm:pb-8 space-y-16 mt-20">
                            <ListContainer
                                    titleSection={ titleSection() }
                                    elements={ ["Transaction ID", "Age", "Block", "Type name", "Fee"] }
                                    elementsConfig={ ["1/5", "1/5", "1/5", "1/5", "1/5"] }
                                    isLoading={ txsRequestState === RequestState.LOADING }
                                    isEmpty={ txs ? txs.length === 0 : false }
                                    tableContent={
                                        txs ? txs.map((b: SCAPI.Transaction & TransactionJSON, index: number) => {
                                            return (
                                                    <tr key={ b.txid }
                                                        data-testid={"tx_row"}
                                                        className={ (index === txs.length - 1 ? "" : "border-b").concat(" border-Gray_text h-12") }>
                                                        <td className="text-sm pr-8 truncate">
                                                            <Link
                                                                    data-testid={"link_txId"}
                                                                    className="hover:underline"
                                                                    to={ URLProvider.URL_TRANSACTION_DETAIL.replace(
                                                                            ":txId",
                                                                            b.txid!
                                                                    ) }
                                                            >
                                                                { b.txid }
                                                            </Link>
                                                        </td>

                                                        <td className="text-sm pr-8 text-white truncate">{ currentDate && txDateToShow(currentDate, b) }</td>
                                                        <td className="text-sm pr-8">
                                                            { b.blockHeight === -1
                                                                    ? <span className="text-white">Pending</span>
                                                                    : <Link
                                                                            data-testid={"link_blockHeight"}
                                                                            className="hover:underline"
                                                                            to={ URLProvider.URL_BLOCK_DETAIL.replace(
                                                                                    ":blockId",
                                                                                    b.blockHash!
                                                                            ) }
                                                                    >
                                                                        { b.blockHeight }
                                                                    </Link>
                                                            }

                                                        </td>
                                                        <td className="text-sm text-white pr-8 truncate">{ b.typeName }</td>
                                                        <td className="text-sm text-right text-white truncate">
                                                <span className="inline">{ b.fee || b.fee === 0
                                                        ? bigNumberFormat(b.fee, Constants.ZENPrecision)
                                                        : "-" } </span>
                                                            <span className="inline font-bold">ZEN</span>
                                                        </td>
                                                    </tr>
                                            )
                                        }) : undefined
                                    }
                            >
                            </ListContainer>
                        </div>
                        { calendarOpen && calendarModal && (
                                <Modal
                                        className="min-w-0 h-0"
                                        isOpen={ calendarOpen }
                                        shouldCloseOnOverlayClick
                                        onRequestClose={ () => setCalendarOpen(false) }
                                >
                                    { calendarComponent(true) }
                                </Modal>
                        ) }
                        { calendarOpen && !calendarModal && calendarCoordinates && (
                                <div
                                        className="absolute"
                                        style={ {
                                            left: `${ calendarCoordinates.x }px`,
                                            top: `${ calendarCoordinates.y + 20 }px`
                                        } }
                                >
                                    { calendarComponent(false) }
                                </div>
                        ) }
                    </div>
                </main>
            </div>
    )
}

export default Transactions
