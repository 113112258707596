import React from "react"
import ReactTooltip from "react-tooltip"
import infoIcon from "../assets/info_icon.png"

export type InfoToolTip = {
    title: string
    info: any
}

const InfoToolTip: React.FC<InfoToolTip> = ({info, title}) => {
    const fullTitle = title.concat("-info-tool-tip")
    return (
        <button className="pb-4">
            <ReactTooltip
                id={ fullTitle }
                effect="solid"
                multiline={ true }
                place="right"
            >
                <span className="text-sm text-center">{ info }</span>
            </ReactTooltip>
            <img
                className="w-5 cursor-pointer"
                src={ infoIcon }
                data-tip={ fullTitle }
                data-for={ fullTitle }
                data-background-color="#1D1E26"
            />
        </button>
    )
}

export default InfoToolTip
