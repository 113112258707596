import React from "react"
import ReactTooltip from "react-tooltip";

export type CustomButton = {
    capitalLettersOff?: boolean
    label: string
    customClass?: string
    href?: string
    openNewTab?: boolean
    isDisabled?: boolean
    tooltip?: string
}

const SolidButton: React.FC<React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement> &
        CustomButton> = ({
                             capitalLettersOff,
                             type = "button",
                             label,
                             customClass,
                             href,
                             openNewTab,
                             isDisabled = false,
                             tooltip,
                             ...props
                         }) => {

    const button = (
            <button
                    className={ `bg-Main_bckgrnd px-4 md:px-6 text-white font-bold text-base !leading-10 disabled:cursor-not-allowed border border-ZBF_green hover:bg-ZBF_green rounded-[10px] ${ customClass } ${
                            capitalLettersOff ? "" : "tracking-[0.25em]"
                    }` }
                    disabled={ isDisabled }
                    type={ type }
                    { ...props }
            >
                { tooltip ? (
                                <>
                                    <ReactTooltip
                                            id="buttonTooltip"
                                            effect="solid"
                                            multiline
                                    />
                                    <span
                                            className="font-bold"
                                            data-tip={ tooltip! }
                                            data-for="buttonTooltip"
                                            data-background-color="#041742"
                                    >
            { capitalLettersOff ? label : label.toUpperCase() }
                                        </span>
                                </>
                        )
                        : (
                                capitalLettersOff ? label : label.toUpperCase()
                        )
                }
            </button>
    )
    return href ? (
        <a
                className="h-full block rounded-[10px]"
                href={ isDisabled ? undefined : href }
                target={ openNewTab ? "_blank" : "" }
                rel="noopener noreferrer"
        >
            { button }
        </a>
    ) : (
            button
    )
}

export default SolidButton