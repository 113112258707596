import { MainchainHeader, SCSignature } from "./Block"
import { GenericProposition, Unlockers } from "./Box"

export namespace SCAPI {
    interface ITransaction {
        chain: string;
        network: string;
        blockHeight: number;
        blockHash?: string;
        blockTime?: Date;
        blockTimeNormalized?: Date;
        fee: number;
        inputCount: number;
        outputCount: number;
        typeName: string;
        modifierTypeId: number;
        txid: string;
    }

    export interface Transaction extends ITransaction{
        unlockers?: Unlockers[];
        newBoxes?: Box[];
    }

    export interface MainchainTransaction extends Transaction {
        mc2scTransactionsMerkleRootHash: string;
        mainchainBlockHash: string;
    }

    export interface FeePaymentsTransaction extends Transaction {
        mempoolTime: Date
    }

    export type BlockJSON = {
        chain?: string
        network?: string
        hash?: string
        height?: number
        version?: number
        time?: number
        timeNormalized?: Date
        previousBlockHash?: string
        nextBlockHash?: string
        transactionCount?: number
        mainchainHeaders?: MainchainHeader[]
        txs?: string[]
        confirmations?: number
        parentBlockHash?: string
        sidechainTransactionsMerkleRootHash?: string
        ommersMerkleRootHash?: string
        ommersCumulativeScore?: number
        signature?: SCSignature
    }

    export type StatusJSON = {
        blocksNumber: number
        network: string
        currentSyncStatus: string
        startDate: string
        syncedBlocks: number
        lastBlockHashSynced: string
        lastBlockHeightSynced: number
        sidechainId?: {
            sidechainId: string
        }
    }

    // Tokens sidechain

    export type Box = {
        id: string;
        typeName: string;
        nonce: string;
        proposition?: GenericProposition;
        vrfPubKey?: GenericProposition;
        blockSignProposition?: GenericProposition;
        value?: number;
        mintHeight?: number;
    }

    export type TokenBoxType = Box & {
        symbol: string;
        uuid: string;
        tokenType: TokenType;
        precision: number;
        currentSupply: bigint;
        maxSupply: bigint;
        owner: string;
        name: string
        metadata: Record<string, string>;
        version: number;
        burnable: boolean;
        multiMintable: boolean;
        baseURI: string;
    }

    export type TokenFungibleBoxType = Box & {
        uuid: string;
        tokenValue: bigint;
        version: number;
    }

    export type TokenNonFungibleBoxType = Box & {
        uuid: string;
        serialNumber: string;
        presalePrice: bigint;
        updatableMetadata: string;
        metadata: string;
        version: number;
        proposition: GenericProposition;
    }

    export enum TokenType {
        Fungible = "Fungible",
        NonFungible = "NonFungible"
    }

    // Base explorer types

    export enum SidechainTransactionsTypes {
        MainchainBlockReference = "MainchainBlockReference",
        MC2SCAggregatedTransaction = "MC2SCAggregatedTransaction",
        SidechainCoreTransaction = "SidechainCoreTransaction",
        FeePaymentsTransaction = "FeePaymentsTransaction",
    }

    export enum SidechainBoxTypes {
        ZenBox = "ZenBox",
        BackwardTransferBox = "BackwardTransferBox",
        ForgerBox = "ForgerBox",
        WithdrawalRequestBox = "WithdrawalRequestBox"
    }

    // Auditchain types

    export enum TokenChainTransactionTypes {
        TokenTypeDeclareTransaction = "TokenDeclare",
        TokenFungibleMintTransaction = "TokenFungibleMint",
        TokenFungibleTransferTransaction = "TokenFungibleTransfer",
        TokenFungibleBurn = "TokenFungibleBurn",
        TokenNonFungibleMint = "TokenNonFungibleMint",
        TokenNonFungibleTransfer = "TokenNonFungibleTransfer",
        TokenNonFungibleUpdateMetadata = "TokenNonFungibleUpdateMetadata"
    }

    export enum TokenChainBoxTypes {
        Token = "Token",
        TokenFungible = "TokenFungible",
        TokenNonFungible = "TokenNonFungible",
    }
}
