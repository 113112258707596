import React from "react"
import { Link } from "react-router-dom"
import { bigNumberFormat, getElapsedTime, getFormattedDate, splitStringMiddle } from "../../utils/Utils"

export enum InfoType {
    text,
    textColoured,
    date,
    amount,
    link,
    linkExtended,
}

export enum TextColouredOption {
    green,
    yellow
}

export type InfoTypeTextColoured = {
    value: string
    color: TextColouredOption
}

export type InfoTypeAmount = {
    amount: number | bigint
    currency: string
    precision: number
}

export type InfoTypeLink = {
    text: string
    link: string
    external?: boolean
    newTab?: boolean
}

export type InfoTypeLinkExtended = InfoTypeLink & {
    additionalInfo: string
}

export type InfoItem = {
    title: string
    value: string | Date | InfoTypeTextColoured | InfoTypeAmount | InfoTypeLink | InfoTypeLinkExtended
    type: InfoType
}

export type InfoBlockProps = {
    fields: Array<InfoItem>
    textRight?: boolean
    fullBorder?: boolean
    customFontSize?: string
}

const InfoBlock: React.FC<InfoBlockProps> = (
        {
            fields,
            textRight,
            fullBorder,
            customFontSize
        }
) => {
    const beforeAfterCssRules = `relative after:absolute before:absolute
    after:w-4 before:w-4 after:h-full before:h-full after:border before:border
    after:top-0 before:top-0 after:right-0 before:left-0
    after:border-l-0 before:border-r-0
    after:border-white before:border-white`

    const fontSize = () => {
        return customFontSize ? `text-[${ customFontSize! }]` : "text-base"
    }

    const textColor = (color: TextColouredOption) => {
        switch (color) {
            case TextColouredOption.green:
                return `text-ZBF_green`
            case TextColouredOption.yellow:
                return `text-yellow-400`
        }
    }

    const externalLink = (newTab: boolean, contentLink: InfoTypeLink) => {
        if (newTab) {
            return (
                    <a className={ `${ textRight ? "text-right" : "" } hover:underline font-normal ${ fontSize() }` }
                       target="_blank"
                       rel="noopener noreferrer"
                       href={ contentLink.link }
                       title={ contentLink.text }>
                        { contentLink.text }
                    </a>
            )
        } else {
            return (
                    <a className={ `${ textRight ? "text-right" : "" } hover:underline font-normal ${ fontSize() }` }
                       href={ contentLink.link }
                       title={ contentLink.link }>
                        { contentLink.text }
                    </a>
            )
        }
    }

    const formatItemContent = (item: InfoItem) => {
        switch (item.type) {
            case InfoType.text:
                const contentString = item.value as string
                return (
                        <p className={ `${ textRight ? "text-right" : "" } ${ fontSize() } font-normal text-Content-gray truncate` }
                           title={ contentString }>{ contentString }</p>
                )
            case InfoType.textColoured:
                const contentStringColured = item.value as InfoTypeTextColoured
                return (
                        <p className={ `${ textRight ? "text-right" : "" } ${ fontSize() } ${ textColor(contentStringColured.color) } font-normal truncate` }
                           title={ contentStringColured.value }>{ contentStringColured.value }</p>
                )
            case InfoType.date:
                const contentDate = item.value as Date
                const elapsedTime = getElapsedTime(contentDate)
                const formattedDate = getFormattedDate(contentDate)
                return (
                        <p className={ `${ textRight ? "text-right" : "" } ${ fontSize() } font-normal text-Content-gray truncate` }
                           title={ formattedDate }>{ elapsedTime } ({ formattedDate })</p>
                )
            case InfoType.amount:
                const contentAmount = item.value as InfoTypeAmount

                const contentToDisplay = (contentAmount.currency === "$")
                        ? `$${ bigNumberFormat(contentAmount.amount, contentAmount.precision) ?? "-" }`
                        : `${ bigNumberFormat(contentAmount.amount, contentAmount.precision).concat(` ${ contentAmount.currency }`) ?? "-" }`
                return (
                        <p className={ `${ textRight ? "text-right" : "" } ${ fontSize() } font-normal text-Content-gray` }
                           title={ contentToDisplay }>{ contentToDisplay }</p>
                )
            case InfoType.link:
                const contentLink = item.value as InfoTypeLink
                return (
                        contentLink.external
                                ? externalLink(contentLink.newTab ?? false, contentLink)
                                : <Link
                                        title={ contentLink.text }
                                        className={ `${ textRight ? "text-right" : "" } ${ fontSize() } hover:underline font-normal` }
                                        to={ contentLink.link }>
                                    { contentLink.text }
                                </Link>
                )
            case InfoType.linkExtended:
                const contentLinkExtended = item.value as InfoTypeLinkExtended
                return (
                        <div className="inline-flex">
                            <Link title={ contentLinkExtended.text } className="hover:underline font-normal"
                                  to={ contentLinkExtended.link }>
                                { splitStringMiddle(contentLinkExtended.text, 50) }
                            </Link>
                            <p className={ `${ textRight ? "text-right" : "" } ml-1 ${ fontSize() } font-normal text-Content-gray` }>{ contentLinkExtended.additionalInfo }</p>
                        </div>
                )
        }
    }

    return (
            <div className="w-full">
                <div className={ `${ fullBorder ? "border border-white" : beforeAfterCssRules } text-white font-bold` }>
                    <div className="h-full grid py-6 px-8 space-y-3 ">
                        {
                            fields.map((item: InfoItem) => {
                                return (
                                        <div className="grid grid-cols-5 space-x-2"
                                             key={ `${ item.title }_${ item.value }` }>
                                            <p className={ `${ fontSize() } font-normal text-Gray_text col-span-2` }>{ item.title }</p>
                                            <div className="col-span-3 truncate">
                                                { formatItemContent(item) }
                                            </div>
                                        </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
    )
}

export default InfoBlock