import React, { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"
import ReactTooltip from "react-tooltip"
import copyIcon from "../../assets/copy_icon.png"
import ListHeader from "../../components/ListHeader/ListHeader"
import TokenDetailInfo from "../../components/TokenDetailInfo"
import TransactionsList from "../../components/TransactionsList"
import { getTokens, getTransactionsByToken } from "../../network/APIRepository"
import { SCAPI } from "../../network/explorer_types/SCAPI"
import { Constants } from "../../utils/Constants"
import { delay } from "../../utils/Utils"
import TokenMintSpinner from "../../templates/TokenMintSpinner";

const TokenDetail: React.FC = () => {
    const params = useParams()
    const {tokenId} = params

    const [symbol, setSymbol] = useState<string | undefined>(undefined)
    const [currentPage, setCurrentPage] = useState<number>(0)
    const [totalPages, setTotalPages] = useState<number | undefined>(undefined)
    const {
        data: tokens,
        error,
        isLoading
    } = useQuery<[SCAPI.TokenBoxType[], number] | void>(["getTokens", tokenId], () => getTokens(undefined, tokenId))

    const {
        data: txs,
        error: txsError,
        isLoading: txsIsLoading
    } = useQuery<any>(["getTransactionsByToken", tokenId, currentPage], () => getTransactionsByToken(tokenId as string, currentPage))

    useEffect(() => {
        if (tokens && tokens[0].length > 0) {
            setSymbol(tokens[0][0].symbol)
        }
        if (txs) {
            setTotalPages(txs[1])
        }
    }, [txs])

    const titleSection = () => {
        return (
                <ListHeader
                        title="Latest Transactions"
                        currentPage={ currentPage + 1 }
                        totalPages={ txs ? txs[1] === 0 ? 1 : txs[1] : undefined }
                        hasPreviousPage={ currentPage > 0 }
                        onPreviousClick={ () => {
                            setCurrentPage(currentPage - 1)
                        } }
                        onFirstClick={ () => {
                            setCurrentPage(0)
                        } }
                        onLastClick={ () => {
                            if ((totalPages && currentPage === totalPages - 1) ||
                                    totalPages === 0) {
                                return
                            }

                            setCurrentPage(totalPages! - 1)
                        } }
                        hasNextPage={ totalPages ? totalPages > currentPage + 1 : false }
                        onNextClick={ () => {
                            setCurrentPage(currentPage + 1)
                        } }/>
        )
    }

    return (
            <div>
                <main>
                    <div className="max-w-screen-xl ml-auto mr-auto xl:grid">
                        <>
                            <div className="px-8 sm:pb-8 space-y-16 mt-20">
                                <>
                                    { isLoading && (
                                            <div className="w-20 mx-auto">
                                                <TokenMintSpinner/>
                                            </div>
                                    ) }
                                    { tokens && tokens[0].length === 0 && (
                                            <div
                                                    className="flex justify-center items-center text-2xl text-white my-40 font-bold">
                                                <h1>
                                                    Sorry, we couldn&apos;t find the token you&apos;re looking for.
                                                </h1>
                                            </div>
                                    ) }
                                    { tokens && tokens[0].length > 0 && (
                                            <div>
                                                <div className="flex mt-2">
                                                    <p data-testid={ "token_name_title" }
                                                       className="font-bold text-white text-4xl mb-1 mr-6">{ tokens[0][0].name }</p>
                                                    <p data-testid={ "token_symbol_subtitle" }
                                                       className="font-bold text-white text-xl mt-3">({ tokens[0][0].symbol.toUpperCase() })</p>
                                                </div>
                                                <div className="inline-flex mt-2">
                                                    <p className="text-Gray_text text-lg mr-2">Token Unique ID:</p>
                                                    <p data-testid={ "token_uuid_label" }
                                                       className="text-white text-lg">{ tokens[0][0].uuid }</p>
                                                    <button data-testid={ "token_uuid_copy" }
                                                            data-event="click" className="ml-6">
                                                        <img
                                                                data-tip="Token Unique ID copied to the pasteboard!"
                                                                data-for="description"
                                                                data-background-color="#1D1E26"
                                                                className="h-7 copy-icon" src={ copyIcon }/>
                                                        <ReactTooltip
                                                                id="description"
                                                                effect="solid"
                                                                multiline
                                                                event="click"
                                                                afterShow={ async () => {
                                                                    navigator.clipboard.writeText(tokens[0][0].uuid)
                                                                    await delay(2000)
                                                                    ReactTooltip.hide()
                                                                } }
                                                        />
                                                    </button>
                                                </div>
                                                <div className="mt-8">
                                                    <TokenDetailInfo token={ tokens[0][0] } isNFTCollection={ false }/>
                                                </div>
                                            </div>
                                    ) }
                                </>
                            </div>

                            { tokens && tokens[0].length > 0 && (
                                    <div className="px-8 sm:pb-8 space-y-16 mt-20">
                                        <TransactionsList isLoading={ txsIsLoading }
                                                          isEmpty={ txs !== undefined && txs[0].length === 0 }
                                                          symbol={ symbol }
                                                          precision={ tokens ? tokens[0][0].precision : Constants.ZENPrecision }
                                                          titleSection={ titleSection() }
                                                          filteredTxs={ txs ? txs[0] : undefined }/>
                                    </div>
                            ) }
                        </>
                    </div>
                </main>
            </div>
    )
}

export default TokenDetail
