import React, { Suspense } from "react"
import ReactDOM from "react-dom"
import "./index.css"
import { I18nextProvider } from "react-i18next"
import { QueryClient, QueryClientProvider } from "react-query"
import App from "./App"
import i18next from "./translations/translationsCommons"

import { createInstance, MatomoProvider } from "@jonkoops/matomo-tracker-react"
import { BrowserRouter } from "react-router-dom"

const matomoInstance = createInstance({
    disabled: !process.env.REACT_APP_MATOMO_URL,
    urlBase: process.env.REACT_APP_MATOMO_URL!,
    siteId: +process.env.REACT_APP_MATOMO_SITE_ID!,
    linkTracking: true
})

declare global {
    interface Window {
        plausible: any
    }
}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false
        }
    }
})


ReactDOM.render(
    <React.StrictMode>
        <QueryClientProvider client={ queryClient }>
            <I18nextProvider i18n={ i18next }>
                <Suspense fallback="loading">
                    <MatomoProvider value={ matomoInstance }>
                        <BrowserRouter>
                            <App/>
                        </BrowserRouter>
                    </MatomoProvider>
                </Suspense>
            </I18nextProvider>
        </QueryClientProvider>
    </React.StrictMode>,
    document.getElementById("root")
)
