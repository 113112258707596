import React from "react"
import { getNextDate, getPreviousDate, isAfterToday, isToday } from "../../utils/Utils"
import arrowCollapse from "../../assets/green_arrow_down.png"
import calendar from "../../assets/calendar-green.png"

export type ListHeaderProps = {
    title: string
    secondaryTitle?: string
    currentPage?: number
    totalPages?: number
    currentCalendarDate?: Date,
    onCalendarClicked?: (coordinates: [x: number, y: number]) => void,
    onCalendarPreviousDateSelected?: () => void,
    onCalendarNextDateSelected?: () => void,
    hasPreviousPage?: boolean,
    onPreviousClick?: () => void
    hasNextPage?: boolean,
    onNextClick?: () => void
    onFirstClick?: () => void
    onLastClick?: () => void
}

const ListHeader: React.FC<ListHeaderProps> = (
        {
            title,
            secondaryTitle,
            currentPage,
            totalPages,
            currentCalendarDate,
            onCalendarClicked,
            onCalendarPreviousDateSelected,
            onCalendarNextDateSelected,
            hasPreviousPage,
            onPreviousClick,
            hasNextPage,
            onNextClick,
            onFirstClick,
            onLastClick
        }
) => {

    const paginationBlock = () => {
        return (
                <div className="ml-auto grid grid-cols-10 justify-items-center">
                    <button onClick={ onFirstClick }
                            disabled={ currentPage === 1 }
                            data-testid={"pagination_button_first"}
                            className="pagination-button text-ZBF_green h-full col-span-2 border border-Gray_text hover:border-ZBF_green px-4">First
                    </button>
                    <button onClick={ onPreviousClick }
                            disabled={ !hasPreviousPage }
                            data-testid={"pagination_button_previous"}
                            className="pagination-button text-ZBF_green h-full col-span-1 border border-Gray_text hover:border-ZBF_green px-4">
                        <img className="pagination-arrow rotate-90 w-3 object-contain" src={ arrowCollapse }/>
                    </button>
                    <span
                            data-testid={"pagination_indicator"}
                            className="text-Gray_text text-base col-span-4 mt-2 whitespace-nowrap">{ `Page ${ currentPage } of ${ totalPages ?? "-" }` }</span>
                    <button onClick={ onNextClick }
                            disabled={ !hasNextPage }
                            data-testid={"pagination_button_next"}
                            className="pagination-button text-ZBF_green h-full col-span-1 border border-Gray_text hover:border-ZBF_green px-4">
                        <img className="pagination-arrow -rotate-90 w-3 object-contain" src={ arrowCollapse }/>
                    </button>
                    <button onClick={ onLastClick }
                            disabled={ !hasNextPage }
                            data-testid={"pagination_button_last"}
                            className="pagination-button text-ZBF_green h-full col-span-2 border border-Gray_text hover:border-ZBF_green px-4">Last
                    </button>
                </div>
        )
    }

    const previousDateButton = (currentCalendarDate: Date) => {
        return (
                <button
                        data-testid={ "previous-date-button" }
                        className="text-normal lg:px-1 px-4 text-ZBF_green hover:text-white"
                        onClick={ () => onCalendarPreviousDateSelected!() }
                >
                    ← { getPreviousDate(currentCalendarDate) }
                </button>
        )
    }

    const nextDateButton = (currentCalendarDate: Date) => {
        return (
                <button
                        data-testid={ "next-date-button" }
                        className="text-normal lg:px-1 px-4 text-ZBF_green disabled:text-Gray_text hover:text-white"
                        disabled={ currentCalendarDate ? isAfterToday(currentCalendarDate!) || isToday(currentCalendarDate!) : true }
                        onClick={ () => onCalendarNextDateSelected!() }
                >
                    { getNextDate(currentCalendarDate) } →
                </button>
        )
    }

    const calendarButton = () => {
        return (
                <button
                        data-testid={ "calendar-button" }
                        className="col-span-2 mx-4"
                        type="button"
                        onClick={ (event) => {
                            onCalendarClicked!([event.clientX, event.clientY])
                        } }
                >
                    <img
                            className="mx-auto h-7 calendar-button"
                            src={ calendar }
                            alt="Calendar"
                    />
                </button>
        )
    }

    const titleSection = () => {
        const secondaryTitleComponent = <p className="text-lg text-Content_gray mt-2"
                                           data-testid={ "header-secondary-title" }>{ secondaryTitle ?? "" }</p>
        if (secondaryTitle && currentCalendarDate && currentPage !== undefined) {
            return (
                    <div>
                        <p className="font-bold text-white text-[34px] mb-1 mr-4">{ title }</p>
                        { secondaryTitleComponent }
                        <div className="inline-flex w-full mb-3 mt-4 pl-2">
                            <div className="h-full contents">
                                { previousDateButton(currentCalendarDate) }
                                { calendarButton() }
                                { nextDateButton(currentCalendarDate) }
                            </div>

                            { paginationBlock() }
                        </div>
                    </div>
            )
        } else if (currentCalendarDate && currentPage !== undefined) {
            return (
                    <div>
                        <p className="font-bold text-white text-[34px] mb-1 mr-4">{ title }</p>
                        <div className="inline-flex w-full mb-3 mt-4 pl-2">
                            <div className="h-full contents">
                                { previousDateButton(currentCalendarDate) }
                                { calendarButton() }
                                { nextDateButton(currentCalendarDate) }
                            </div>

                            { paginationBlock() }
                        </div>
                    </div>
            )
        } else if (secondaryTitle && currentPage !== undefined) {
            return (
                    <div>
                        <p className="font-bold text-white text-[34px] mb-1 mr-4">{ title }</p>
                        <div className="inline-flex w-full mb-3">
                            { secondaryTitleComponent }
                            { paginationBlock() }
                        </div>
                    </div>
            )
        } else if (secondaryTitle) {
            return (
                    <div>
                        <p className="font-bold text-white text-[34px] mb-1 mr-4">{ title }</p>
                        <div className="inline-flex w-full mb-3">
                            { secondaryTitleComponent }
                        </div>
                    </div>
            )
        } else if (currentPage !== undefined) {
            return (
                    <div className="inline-flex w-full">
                        <p className="font-bold text-white text-[34px] mb-1 mr-4 whitespace-nowrap">{ title }</p>
                        <div className="inline-flex w-full mb-3">
                            { paginationBlock() }
                        </div>
                    </div>
            )
        } else {
            return (
                    <h2 className="font-bold text-white text-[34px] mb-4">{ title }</h2>
            )
        }
    }

    return (
            titleSection()
    )
}

export default ListHeader
