import React from "react"
import { useQuery } from "react-query"
import { getStatus } from "../../network/APIRepository";
import ContentContainer from "../../templates/ContentContainer";

const Footer = () => {
    const {
        data,
    } = useQuery<any>("getStatus", () => getStatus())

    return (
            <footer id="footer" className="py-8 bg-Footer_bckgrnd border-t border-ZBF_green bottom-0 w-full">
                <ContentContainer>
                    <div className="flex items-center justify-between text-white">
                        { data?.sidechainId?.sidechainId &&
                                <div>TokenMint Sidechain ID: { data.sidechainId.sidechainId }</div> }
                        <div>© { new Date().getFullYear() } Horizen. All Rights Reserved.</div>
                    </div>
                </ContentContainer>
            </footer>
    )
}

export default Footer
