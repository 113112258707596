import axios, { AxiosInstance } from "axios"
import { SearchFilterType } from "../components/Header/Header"
import { NFT } from "../pages/AddressDetail"
import { DateFormat, getFormattedDate } from "../utils/Utils"

import { APIURLProvider } from "./APIURLProvider"
import { handleError } from "./ErrorHandler"
import { SCAPI } from "./explorer_types/SCAPI"
import { Balance, TokenchainAPI } from "./explorer_types/TokenchainAPI"
import { TransactionJSON } from "./explorer_types/Transaction"

export class APIRepository {
    static axiosInstance = (): AxiosInstance => {
        return axios.create()
    }
}

async function performAxiosGetRequest(requestURL: string, requestParams?: any): Promise<any> {
    return APIRepository.axiosInstance()
        .get(requestURL, { params: requestParams })
        .then((response) => {
            return Promise.resolve(response)
        })
        .catch((error) => {
            return Promise.reject(handleError(error))
        })
}

async function performPaginatedTransactionsRequest(requestURL: string, requestParams?: any): Promise<[ txs: Array<SCAPI.Transaction & TransactionJSON>, pagesTotal: number ] | void> {
    return performAxiosGetRequest(requestURL, requestParams)
        .then((response) => {
            return Promise.all([
                response.data.txs.map((tx: any) => parseTransaction(tx)),
                response.data.pagesTotal
            ])
        })
        .catch((error) => {
            return Promise.reject(handleError(error))
        })
}

function parseTransaction(
    tx: any
): (SCAPI.Transaction & TransactionJSON) | undefined {
    switch (tx.typeName) {
        case SCAPI.SidechainTransactionsTypes.MainchainBlockReference:
        case SCAPI.SidechainTransactionsTypes.MC2SCAggregatedTransaction:
            return tx as unknown as TransactionJSON &
                SCAPI.MainchainTransaction
        case SCAPI.SidechainTransactionsTypes.SidechainCoreTransaction:
            return tx as unknown as TransactionJSON &
                SCAPI.Transaction
        case SCAPI.TokenChainTransactionTypes.TokenTypeDeclareTransaction:
            return tx as unknown as TransactionJSON &
                TokenchainAPI.TokenDeclareTransactionType
        case SCAPI.TokenChainTransactionTypes.TokenFungibleMintTransaction:
            return tx as unknown as TransactionJSON &
                TokenchainAPI.TokenFungibleMintTransaction
        case SCAPI.TokenChainTransactionTypes.TokenFungibleTransferTransaction:
        case SCAPI.TokenChainTransactionTypes.TokenFungibleBurn:
        case SCAPI.TokenChainTransactionTypes.TokenNonFungibleMint:
        case SCAPI.TokenChainTransactionTypes.TokenNonFungibleTransfer:
        case SCAPI.TokenChainTransactionTypes.TokenNonFungibleUpdateMetadata:
            return tx as unknown as TransactionJSON &
                TokenchainAPI.TokenTransactionType
        case SCAPI.SidechainTransactionsTypes.FeePaymentsTransaction:
            return tx as unknown as TransactionJSON &
                SCAPI.FeePaymentsTransaction
        default:
            return tx
    }
}

// optional query params:
//    limit: Number indicating max number of results to bring
//    pageNum: Page number
export async function getTokens(
    tokenSymbol?: string,
    tokenUuid?: string,
    limit?: number,
    pageNum?: number
): Promise<[ tokens: Array<SCAPI.TokenBoxType>, totalPages: number ] | void> {

    const requestURL = APIURLProvider.baseUrl + APIURLProvider.URL_TOKENS

    let requestParams: any = {}

    if (tokenSymbol) {
        requestParams = {
            ...requestParams,
            tokenSymbol
        }
    }

    if (tokenUuid) {
        requestParams = {
            ...requestParams,
            tokenUuid
        }
    }

    if (limit) {
        requestParams = {
            ...requestParams,
            limit
        }
    }

    if (pageNum) {
        requestParams = {
            ...requestParams,
            pageNum
        }
    }

    return performAxiosGetRequest(requestURL, requestParams)
        .then((response) => {
            return Promise.all([
                response.data.tokens.map((b: any) => {
                    return b as unknown as SCAPI.TokenBoxType
                }),
                response.data.pagesTotal
            ])
        })
        .catch((error) => {
            return Promise.reject(error)
        })
}

// optional query params:
//    blocksDate format: 2021-12-20
//    limit: Number indicating max number of results to bring
//    since: Starting height number
//    startTimestamp: Timestamp the blocks will come after
//    pageNum: Page number
export async function getBlocks(
    blockDate?: Date,
    limit?: number,
    since?: number,
    startTimestamp?: number,
    page?: number
): Promise<[ blocks: SCAPI.BlockJSON[], pagesTotal: number ] | void> {
    const requestURL = APIURLProvider.baseUrl + APIURLProvider.URL_BLOCKS

    let requestParams = {}
    if (blockDate) {
        requestParams = {
            ...requestParams,
            blockDate: getFormattedDate(blockDate, DateFormat.yyyymmdd)
        }
    }

    if (limit) {
        requestParams = {
            ...requestParams,
            limit
        }
    }

    if (since) {
        requestParams = {
            ...requestParams,
            since
        }
    }

    if (startTimestamp) {
        requestParams = {
            ...requestParams,
            startTimestamp
        }
    }

    if (page) {
        requestParams = {
            ...requestParams,
            pageNum: page
        }
    }

    return performAxiosGetRequest(requestURL, requestParams)
        .then((response) => {
            return Promise.all([
                response.data.blocks.map((b: any) => {
                    return b as unknown as SCAPI.BlockJSON
                }),
                response.data.pagesTotal
            ])
        })
        .catch((error) => {
            return Promise.reject(error)
        })
}

export async function getTransactions(
    mempool: boolean,
    txDate?: Date,
    pageNum?: number,
    limit?: number
): Promise<[ txs: Array<SCAPI.Transaction & TransactionJSON>, pagesTotal: number ] | void> {
    const requestURL = APIURLProvider.baseUrl + APIURLProvider.URL_TRANSACTIONS

    let requestParams = {}
    if (txDate) {
        requestParams = {
            ...requestParams,
            txDate: getFormattedDate(txDate, DateFormat.yyyymmdd_UTC)
        }
    }

    if (pageNum) {
        requestParams = {
            ...requestParams,
            pageNum: pageNum
        }
    }

    if (limit) {
        requestParams = {
            ...requestParams,
            limit
        }
    }

    requestParams = {
        ...requestParams,
        mempool
    }

    return performPaginatedTransactionsRequest(requestURL, requestParams)
}

export async function getTransactionsByToken(
    uuid: string,
    pageNum?: number
): Promise<[ txs: Array<SCAPI.Transaction & TransactionJSON>, pagesTotal: number ] | void> {
    const requestURL =
        APIURLProvider.baseUrl +
        APIURLProvider.URL_TRANSACTIONS_BY_TOKEN.replace(
            ":uuid",
            uuid
        )

    let requestParams = {}
    if (pageNum) {
        requestParams = {
            ...requestParams,
            pageNum
        }
    }

    return performPaginatedTransactionsRequest(requestURL, requestParams)
}

export async function getTransactionsByAddress(
    address: string,
    includeBoxes: boolean,
    pageNum?: number
): Promise<[ txs: Array<SCAPI.Transaction & TransactionJSON>, pagesTotal: number ] | void> {
    const requestURL =
        APIURLProvider.baseUrl +
        APIURLProvider.URL_TRANSACTIONS_BY_ADDRESS.replace(
            ":address",
            address
        )

    let requestParams: any = {
        includeBoxes: includeBoxes ? "true" : "false"
    }
    if (pageNum) {
        requestParams = {
            ...requestParams,
            pageNum
        }
    }

    return performPaginatedTransactionsRequest(requestURL, requestParams)
}

export async function getBalanceByAddress(
    address: string
): Promise<Array<Balance> | void> {
    const requestURL =
        APIURLProvider.baseUrl +
        APIURLProvider.URL_BALANCE_BY_ADDRESS.replace(
            ":address",
            address
        )

    return performAxiosGetRequest(requestURL)
        .then((response) => {
            return Promise.all(response.data)
        })
        .catch((error) => {
            return Promise.reject(error)
        })
}

export async function getNFTCollections(
    pageNum: number,
    collectionSymbol?: string,
    collectionUuid?: string
): Promise<[ collections: Array<SCAPI.TokenBoxType>, pagesTotal: number ] | void> {
    const requestURL =
        APIURLProvider.baseUrl +
        APIURLProvider.URL_NFTs

    let requestParams: any = {
        pageNum
    }

    if (collectionUuid) {
        requestParams = {
            ...requestParams,
            tokenUuid: collectionUuid
        }
    }

    if (collectionSymbol) {
        requestParams = {
            ...requestParams,
            tokenSymbol: collectionSymbol
        }
    }

    return performAxiosGetRequest(requestURL, requestParams)
        .then((response) => {
            return Promise.all([
                response.data.tokens.map((b: any) => {
                    return b as unknown as SCAPI.TokenBoxType
                }),
                response.data.pagesTotal
            ])
        })
        .catch((error) => {
            return Promise.reject(error)
        })
}

export async function getNFTsByCollection(
    pageNum: number,
    collectionUuid?: string,
    serialNumber?: string
): Promise<[ nfts: Array<SCAPI.TokenNonFungibleBoxType>, pagesTotal: number ] | void> {
    const requestURL =
        APIURLProvider.baseUrl +
        APIURLProvider.URL_NFTs_BOXES

    let requestParams: any = {
        pageNum
    }

    if (collectionUuid) {
        requestParams = {
            ...requestParams,
            tokenUuid: collectionUuid
        }
    }

    if (serialNumber) {
        requestParams = {
            ...requestParams,
            serialNumber: serialNumber
        }
    }

    return performAxiosGetRequest(requestURL, requestParams)
        .then((response) => {
            return Promise.all([
                response.data.boxes.map((b: any) => {
                    return b as unknown as SCAPI.TokenNonFungibleBoxType
                }),
                response.data.pagesTotal
            ])
        })
        .catch((error) => {
            return Promise.reject(error)
        })
}

export async function getTransactionByNFT(
    pageNum: number,
    collectionUuid: string,
    serialNumber: string
): Promise<[ nfts: Array<SCAPI.Transaction & TransactionJSON>, pagesTotal: number ] | void> {
    const requestURL =
        APIURLProvider.baseUrl +
        APIURLProvider.URL_NFT_TXS.replace(":tokenUuid", collectionUuid)

    let requestParams: any = {}
    if (serialNumber) {
        requestParams = {
            ...requestParams,
            serialNumber
        }
    }

    return performPaginatedTransactionsRequest(requestURL, requestParams)
}

export async function getNFTsByAddress(
    address: string
): Promise<Array<NFT> | void> {
    const requestURL =
        APIURLProvider.baseUrl +
        APIURLProvider.URL_NFTs_BOXES

    let nftsPage = 0
    let maxPages = 10
    let needsToLoadMoreNFTs = true
    let addressNFTs: Array<NFT> = []

    while (needsToLoadMoreNFTs) {
        await APIRepository.axiosInstance()
            .get(requestURL, { params: { pageNum: nftsPage, address } })
            .then((response: any) => {
                addressNFTs.push(...response.data.boxes)
                needsToLoadMoreNFTs = response.data.pagesTotal > nftsPage + 1 && nftsPage < maxPages
                nftsPage = nftsPage + 1
            })
            .catch((error) => {
                needsToLoadMoreNFTs = false
            })
    }

    let collectionsPage = 0
    let needsToLoadMoreCollections = true
    const nftsUUIDs: string[] = addressNFTs.map((nft: any) => {
        return nft.uuid
    })
    const searchTokenUUIDs = [ ...new Set(nftsUUIDs) ]
    let collections: Array<any> = []

    while (needsToLoadMoreCollections) {
        await APIRepository.axiosInstance()
            .get(APIURLProvider.baseUrl +
                APIURLProvider.URL_NFTs, { params: { pageNum: collectionsPage, tokenUuid: `[${ searchTokenUUIDs }]` } })
            .then((response: any) => {
                collections.push(...response.data.tokens)
                needsToLoadMoreCollections = response.data.pagesTotal > collectionsPage + 1 && collectionsPage < maxPages
                collectionsPage = collectionsPage + 1
            })
            .catch((error) => {
                needsToLoadMoreCollections = false
            })
    }

    return Promise.resolve(
        addressNFTs.map((nft: any) => {
            const collection = collections.find((collection: any) => collection.uuid === nft.uuid)
            return {
                serialNumber: nft.serialNumber,
                collectionUuid: nft.uuid,
                collectionName: collection.name,
                collectionSymbol: collection.symbol,
                owner: collection.owner,
                creator: ""
            }
        })
    )
}

export async function getBlockTransactionOrAddress(
    searchTerm: string
): Promise<SearchFilterType | void> {
    const nftsEnabled = process.env.REACT_APP_NFTs_ENABLED ? process.env.REACT_APP_NFTs_ENABLED === "true" : false

    async function makeAddressRequest(
        address: string
    ): Promise<SearchFilterType | void> {
        return getBalanceByAddress(address)
            .then((response) => {
                if (response && response.length > 0)
                    return SearchFilterType.address

                return Promise.reject(handleError("There was an error"))
            })
            .catch((error) => {
                return Promise.reject(handleError(error))
            })
    }

    async function makeNFTCollectionSymbolRequest(
        collectionSymbol: string
    ): Promise<SearchFilterType | void> {
        return getNFTCollections(0, collectionSymbol.toUpperCase())
            .then((response) => {
                if (response && response[ 0 ].length > 0)
                    return SearchFilterType.nftCollection

                return makeAddressRequest(searchTerm) as any
            })
            .catch((error) => {
                return makeAddressRequest(searchTerm) as any
            })
    }

    async function makeTokenSymbolRequest(
        tokenSymbol: string
    ): Promise<SearchFilterType | void> {
        return getTokens(tokenSymbol.toUpperCase())
            .then((response) => {
                if (response && response[ 0 ].length > 0) {
                    return SearchFilterType.token
                } else {
                    if (nftsEnabled) {
                        return makeNFTCollectionSymbolRequest(searchTerm) as any
                    } else {
                        return makeAddressRequest(searchTerm) as any
                    }
                }
            })
            .catch((error) => {
                if (nftsEnabled) {
                    return makeNFTCollectionSymbolRequest(searchTerm) as any
                } else {
                    return makeAddressRequest(searchTerm) as any
                }
            })
    }

    async function makeNFTCollectionRequest(
        tokenUuid: string
    ): Promise<SearchFilterType | void> {
        return getNFTCollections(0, undefined, tokenUuid)
            .then((response) => {
                if (response && response[ 0 ].length > 0)
                    return SearchFilterType.nftCollection

                return makeTokenSymbolRequest(searchTerm) as any
            })
            .catch((error) => {
                return makeTokenSymbolRequest(searchTerm) as any
            })
    }

    async function makeTokenRequest(
        tokenUuid: string
    ): Promise<SearchFilterType | void> {
        return getTokens(undefined, tokenUuid)
            .then((response) => {
                if (response && response[ 0 ].length > 0)
                    return SearchFilterType.token

                if (nftsEnabled) {
                    return makeNFTCollectionRequest(searchTerm) as any
                } else {
                    return makeTokenSymbolRequest(searchTerm) as any
                }
            })
            .catch((error) => {
                if (nftsEnabled) {
                    return makeNFTCollectionRequest(searchTerm) as any
                } else {
                    return makeTokenSymbolRequest(searchTerm) as any
                }
            })
    }

    async function makeTransactionsRequest(
        txId: string
    ): Promise<SearchFilterType | void> {
        return getTransaction(txId)
            .then((response) => {
                if (response)
                    return SearchFilterType.transaction

                return makeTokenRequest(searchTerm) as any
            })
            .catch((error) => {
                return makeTokenRequest(searchTerm) as any
            })
    }

    async function makeBlockRequest(
        hash: string
    ): Promise<SearchFilterType | void> {
        return getBlockBy(hash)
            .then((response) => {
                if (response)
                    return SearchFilterType.block

                return makeTransactionsRequest(searchTerm) as any
            })
            .catch((error) => {
                return makeTransactionsRequest(searchTerm)
            })
    }

    return makeBlockRequest(searchTerm)
}

export async function getBlockBy(
    hashOrHeight: string | number
): Promise<SCAPI.BlockJSON | void> {
    let requestURL: string
    if (Number(hashOrHeight)) {
        requestURL =
            APIURLProvider.baseUrl +
            APIURLProvider.URL_BLOCK_BY_HEIGHT.replace(
                ":height",
                hashOrHeight as string
            )
    } else {
        requestURL =
            APIURLProvider.baseUrl +
            APIURLProvider.URL_BLOCK_BY_HASH.replace(
                ":hash",
                hashOrHeight as string
            )
    }
    return performAxiosGetRequest(requestURL)
        .then((response) => {
            return Promise.resolve(response.data as unknown as SCAPI.BlockJSON)
        })
        .catch((error) => {
            return Promise.reject(error)
        })
}

export async function getTransactionsByBlockHashOrHeight(
    hashOrHeight: string | number,
    includeBoxes?: boolean
): Promise<[ txs: Array<SCAPI.Transaction & TransactionJSON>, pagesTotal: number ] | void> {
    let requestURL: string
    if (Number(hashOrHeight)) {
        requestURL =
            APIURLProvider.baseUrl +
            APIURLProvider.URL_TXS_BY_BLOCK_HEIGHT.replace(
                ":blockHeight",
                hashOrHeight as string
            )
    } else {
        requestURL =
            APIURLProvider.baseUrl +
            APIURLProvider.URL_TXS_BY_BLOCK_HASH.replace(
                ":blockHash",
                hashOrHeight as string
            )
    }

    return performPaginatedTransactionsRequest(requestURL)
}

export async function getTransaction(
    txId: string
): Promise<(SCAPI.Transaction & TransactionJSON) | void> {
    const requestURL =
        APIURLProvider.baseUrl +
        APIURLProvider.URL_TX_BY_ID.replace(":txId", txId)

    return performAxiosGetRequest(requestURL)
        .then((response) => {
            return Promise.resolve(parseTransaction(response.data))
        })
        .catch((error) => {
            return Promise.reject(error)
        })
}

export async function getStatus(): Promise<SCAPI.StatusJSON | void> {
    const requestURL = APIURLProvider.baseUrl + APIURLProvider.URL_STATUS
    return performAxiosGetRequest(requestURL)
        .then((response) => {
            return Promise.resolve(response.data as SCAPI.StatusJSON)
        })
        .catch((error) => {
            return Promise.reject(error)
        })
}
