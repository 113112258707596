import React from "react"
import { bigNumberFormat } from "../utils/Utils"

export type AddressDetailInfoProps = {
    balance: bigint
    precision: number
    totalTransactions: string
}

const AddressDetailInfo: React.FC<AddressDetailInfoProps> = (
        {
            balance,
            precision,
            totalTransactions
        }
) => {
    const beforeAfterCssRules = `relative after:absolute before:absolute
    after:w-4 before:w-4 after:h-full before:h-full after:border before:border
    after:top-0 before:top-0 after:right-0 before:left-0
    after:border-l-0 before:border-r-0
    after:border-white before:border-white`

    return (
            <div className="w-full">
                <div className={ `${ beforeAfterCssRules } text-white font-bold grid-cols-2 grid` }>
                    <div className="col-span-1 space-x-4 grid-cols-2 h-full grid py-10 px-10">
                        <div className="row-span-1 truncate">
                            <p className="text-base text-Gray_text">ZEN Balance</p>
                            <p className="text-base text-white truncate"
                               title={ bigNumberFormat(balance, precision) }>{ bigNumberFormat(balance, precision) }</p>
                        </div>
                        <div className="row-span-1 truncate">
                            <p className="text-base text-Gray_text">No. of total transactions</p>
                            <p className="text-base text-white truncate">{ totalTransactions }</p>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default AddressDetailInfo