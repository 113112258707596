import React from "react"
import { Link } from "../../types/Link"
import LinksGroup from "./LinksGroup"

const Links = () => {

    const categoryNames: {
        [index: string]: string
    } = {
        contact: "Contact",
        about: "About",
        developers: "Developers"
    }

    const linksData: {
        [index: string]: Link[]
    } = {
        contact: [
            {
                label: "General inquiries",
                href: "mailto:info@horizen.io",
                openNewTab: true
            },
            {
                label: "Technical support",
                href: "https://horizen.io/invite/discord",
                openNewTab: true
            },
        ],
        about: [
            {
                label: "Horizen",
                href: "https://www.horizen.io",
                openNewTab: true
            },
            {
                label: "Horizen Labs",
                href: "https://horizenlabs.io",
                openNewTab: true
            },
            {
                label: "TokenMint",
                href: process.env.REACT_APP_TOKENMINT_URL!,
                openNewTab: true
            },
            {
                label: "User Guide",
                href: "https://downloads.horizen.io/file/web-assets/Tokenmint_Platform_User_Guide_v1.0.pdf",
                openNewTab: true
            },
            {
                label: "Zendoo",
                href: "https://www.horizen.io/zendoo",
                openNewTab: true
            },
            {
                label: "Legal",
                href: "https://tokenmint.global/terms",
                openNewTab: true
            }
        ],
        developers: [
            {
                label: "Blaze SDK",
                href: "https://github.com/HorizenOfficial/Sidechains-SDK",
                openNewTab: true
            },
            {
                label: "Horizen Early Adopter Program",
                href: "https://heap.horizen.io",
                openNewTab: true
            },
            {
                label: "API Documentation",
                href: process.env.REACT_APP_BACKEND_URL!,
                openNewTab: true
            }
        ]
    }

    return (
            <div className="grid grid-cols-2 gap-x-10 sm:flex md:space-x-2 lg:space-x-12">
                { Object.keys(linksData).map((linkCategory) => {
                    return (
                            <LinksGroup
                                    title={ categoryNames[linkCategory] }
                                    links={ linksData[`${ linkCategory }`] }
                            />
                    )
                }) }
            </div>
    )
}

export default Links
