import React from "react"
import { Link } from "react-router-dom"
import { SCAPI } from "../network/explorer_types/SCAPI"
import { TransactionJSON } from "../network/explorer_types/Transaction"
import { Constants } from "../utils/Constants"
import { URLProvider } from "../utils/URLProvider"
import { bigNumberFormat, boxesSums, getElapsedTime } from "../utils/Utils"
import ListContainer from "./ListContainer/ListContainer"

export type TransactionsListProps = {
    isLoading: boolean
    isEmpty: boolean
    symbol?: string
    precision: number
    titleSection: JSX.Element
    filteredTxs: (SCAPI.Transaction & TransactionJSON)[]
}

const TransactionsList: React.FC<TransactionsListProps> = (
        {
            isLoading,
            isEmpty,
            symbol,
            precision,
            titleSection,
            filteredTxs
        }
) => {
    const amountFormatted = (tx: SCAPI.Transaction & TransactionJSON) => {
        const sumInfo = boxesSums(tx, symbol)
        const sum = sumInfo.output
        const amount = bigNumberFormat(sum, precision)

        return (
                <td className="text-sm pr-8 text-white truncate" title={ amount.concat(" " + sumInfo.symbol) }>
                    <span className="inline">{ amount } </span>
                    <span
                            className="inline font-bold">{ sumInfo.symbol }</span>
                </td>
        )
    }

    return (
            <ListContainer
                    titleSection={ titleSection }
                    elements={ ["Transaction ID", "Age", "Type Name", "Amount", "Fee"] }
                    elementsConfig={ ["1/5", "1/5", "1/5", "1/5", "1/5"] }
                    isLoading={ isLoading }
                    isEmpty={ isEmpty }
                    tableContent={
                        filteredTxs ? filteredTxs.map((b: any, index: number) => {
                            return (
                                    <tr key={ b.txid }
                                        data-testid={"transactions_list_row"}
                                        className={ (index === filteredTxs.length - 1 ? "" : "border-b").concat(" border-Gray_text h-12") }>
                                        <td className="text-sm pr-8 truncate">
                                            <Link
                                                    className="hover:underline"
                                                    to={ URLProvider.URL_TRANSACTION_DETAIL.replace(
                                                            ":txId",
                                                            b.txid
                                                    ) }
                                            >
                                                { b.txid }
                                            </Link>
                                        </td>

                                        <td className="text-sm pr-8 text-white whitespace-nowrap truncate">{ getElapsedTime(b.blockTime!) }</td>
                                        <td className="text-sm pr-8 text-white truncate">{ b.typeName }</td>
                                        { amountFormatted(b) }
                                        <td className="text-sm text-white text-right truncate">
                                <span
                                        className="inline">{ b.fee || b.fee === 0
                                        ? bigNumberFormat(b.fee, Constants.ZENPrecision)
                                        : "-" }
                                </span>
                                            <span className="inline font-bold"> ZEN</span>
                                        </td>
                                    </tr>
                            )
                        }) : undefined
                    }
            >
            </ListContainer>
    )
}

export default TransactionsList