import React from "react"
import { Helmet } from "react-helmet"
import { Outlet } from "react-router-dom"
import AppFooter from "../components/AppFooter"
import Footer from "../components/Footer/Footer"
import Header from "../components/Header/Header"
import SkinnyBanner from "../components/SkinnyBanner"

const Layout: React.FC<any> = ({children}) => {
    const bannerEnabled = process.env.REACT_APP_WARNING_BANNER_VISIBLE ? process.env.REACT_APP_WARNING_BANNER_VISIBLE === "true" : false
    return (
            <div className="bg-Main_bckgrnd min-h-[100vh] flex flex-col">
                { bannerEnabled && (
                        <SkinnyBanner/>
                ) }
                <Header/>
                <Helmet>
                    <script
                    defer
                    data-domain={process.env.REACT_APP_PLAUSIBLE}
                    src="https://plausible.io/js/plausible.js"
                    ></script>
                 </Helmet>
                { children }
                <Outlet/>
                <AppFooter/>
                <Footer/>
            </div>
    )
}

export default Layout
