import React, { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"
import ReactTooltip from "react-tooltip"
import copyIcon from "../assets/copy_icon.png"
import InfoBlock, { InfoItem, InfoType, TextColouredOption } from "../components/InfoBlock/InfoBlock"
import InfoToolTip from "../components/InfoToolTip"
import { getNFTCollections, getTokens, getTransaction } from "../network/APIRepository"
import { SCAPI } from "../network/explorer_types/SCAPI"
import { getTokenSymbolAndPrecision, TransactionJSON, } from "../network/explorer_types/Transaction"
import { Constants } from "../utils/Constants"
import { URLProvider } from "../utils/URLProvider"
import { bigNumberFormat, boxesSums, delay } from "../utils/Utils"
import TokenMintSpinner from "../templates/TokenMintSpinner";
import TokenChainTransactionTypes = SCAPI.TokenChainTransactionTypes;

const TransactionDetail: React.FC = () => {
    const params = useParams()
    const {txId} = params

    const [symbol, setSymbol] = useState<string | undefined>(undefined)
    const [precision, setPrecision] = useState<number | undefined>(undefined)
    const [symbolRequestNeeded, setSymbolRequestNeeded] = useState<boolean>(false)
    const [tokenUuid, setTokenUuid] = useState<string | undefined>(undefined)

    const {
        data: transaction,
        error,
        isLoading,
    } = useQuery<(SCAPI.Transaction & TransactionJSON) | void>(
            ["getTransaction", txId as string],
            () => getTransaction(txId as string)
    )

    const {
        data: tokens,
        error: tokenError,
        isLoading: tokenIsLoading,
    } = useQuery<[SCAPI.TokenBoxType[], number] | void>(
            [isNFTTx(transaction!) ? "getCollection" : "getTokens", tokenUuid],
            () => {
                return isNFTTx(transaction)
                        ? getNFTCollections(0, undefined, tokenUuid)
                        : getTokens(undefined, tokenUuid)
            },
            {enabled: symbolRequestNeeded && tokenUuid !== undefined}
    )

    function isNFTTx(tx: SCAPI.Transaction & TransactionJSON | undefined | void): boolean {
        return tx?.typeName === TokenChainTransactionTypes.TokenNonFungibleTransfer
    }

    useEffect(() => {
        if (transaction) {
            const [txSymbol, txPrecision] = getTokenSymbolAndPrecision(transaction)
            setSymbol(txSymbol)
            setPrecision(txPrecision)
            setTokenUuid(findTokenUuid(transaction))
            setSymbolRequestNeeded(txSymbol === undefined)
        } else {
            setSymbol(undefined)
            setTokenUuid(undefined)
            setSymbolRequestNeeded(false)
        }
    }, [transaction])

    useEffect(() => {
        if (tokens && tokens[0].length > 0) {
            setSymbol(tokens[0][0].symbol)
            setPrecision(tokens[0][0].precision)
        } else {
            // While TokenNonFungibleTransfer transaction is pending we're showing amount transfered: 0.00000001 ZEN
            // This is because getTokens doesn't bring tokens in mempool
            if (transaction?.typeName === TokenChainTransactionTypes.TokenNonFungibleTransfer) {
                setSymbol(" ")
                setPrecision(0)
            }
        }
    }, [tokens])

    function findTokenUuid(
            tx: SCAPI.Transaction & TransactionJSON
    ): string | undefined {
        if (tx === undefined) {
            return undefined
        }
        const searchInBoxes = (
                input: SCAPI.Box[] | undefined
        ): string | undefined => {
            if (input) {
                const b: any = input!.find((box: any) => {
                    return box.uuid !== undefined
                })
                if (b) {
                    return b.uuid
                }
            }
            return undefined
        }
        const uuidInVin = searchInBoxes(tx.vin)
        const uuidInVout = searchInBoxes(tx.vout)

        return uuidInVin ?? uuidInVout
    }

    const propositionInfoItem = (box: SCAPI.Box): InfoItem[] => {
        switch (box.typeName) {
            case SCAPI.SidechainBoxTypes.WithdrawalRequestBox:
                return [{
                    title: "Mainchain Address",
                    value: {
                        text: (box as any).proposition?.mainchainAddress ?? "",
                        link:
                                process.env.REACT_APP_MAINCHAIN_EXPLORER_URL?.concat(
                                        "/address/"
                                ).concat((box as any).proposition?.mainchainAddress) ?? "-",
                        external: true,
                        newTab: true,
                    },
                    type: InfoType.link,
                }]
            case SCAPI.TokenChainBoxTypes.TokenNonFungible:
                return [{
                    title: "Owner",
                    value: {
                        text: (box as any).proposition?.ownerProposition?.publicKey ?? "",
                        link: URLProvider.URL_ADDRESS_DETAIL.replace(
                                ":address",
                                (box as any).proposition?.ownerProposition?.publicKey ?? ""
                        ),
                    },
                    type: InfoType.link,
                },
                    {
                        title: "Publisher",
                        value: {
                            text: (box as any).proposition?.publisherProposition?.publicKey ?? "",
                            link: URLProvider.URL_ADDRESS_DETAIL.replace(
                                    ":address",
                                    (box as any).proposition?.publisherProposition?.publicKey ?? ""
                            ),
                        },
                        type: InfoType.link,
                    }]
            default:
                return [{
                    title: "Address",
                    value: {
                        text: box.proposition?.publicKey ?? "",
                        link: URLProvider.URL_ADDRESS_DETAIL.replace(
                                ":address",
                                box.proposition?.publicKey ?? ""
                        ),
                    },
                    type: InfoType.link,
                }]
        }
    }

    const fieldsForBox = (box: SCAPI.Box): InfoItem[] => {
        let output: InfoItem[] = [
            {
                title: box.typeName,
                value: "",
                type: InfoType.text,
            },
            {
                title: "Box ID",
                value: box.id,
                type: InfoType.text,
            },
            ...propositionInfoItem(box),
        ]

        switch (box.typeName) {
            case SCAPI.TokenChainBoxTypes.TokenNonFungible:
                const tokenNonFungibleBox = box as SCAPI.TokenNonFungibleBoxType
                output = [
                    ...output,
                    {
                        title: "Collection ID",
                        value: tokenNonFungibleBox.uuid,
                        type: InfoType.text,
                    },
                    {
                        title: "Token ID",
                        value: tokenNonFungibleBox.serialNumber,
                        type: InfoType.text,
                    }
                ]
                break
            case SCAPI.TokenChainBoxTypes.TokenFungible:
                const tokenFungibleBox = box as SCAPI.TokenFungibleBoxType
                output = [
                    ...output,
                    {
                        title: "Value",
                        value: {
                            amount: tokenFungibleBox.tokenValue!,
                            currency: symbol ?? Constants.ZENSymbol,
                            precision: precision ?? Constants.ZENPrecision,
                        },
                        type: InfoType.amount,
                    },
                ]
                break
            case SCAPI.TokenChainBoxTypes.Token:
                const tokenBox = box as SCAPI.TokenBoxType
                output = [
                    ...output,
                    {
                        title: "Token Symbol",
                        value: tokenBox.symbol,
                        type: InfoType.text,
                    },
                    {
                        title: "Token Type",
                        value: tokenBox.tokenType,
                        type: InfoType.text,
                    },
                ]
                break
            default:
                output = [
                    ...output,
                    {
                        title: "Value",
                        value: {
                            amount: box.value!,
                            currency: Constants.ZENSymbol,
                            precision: Constants.ZENPrecision,
                        },
                        type: InfoType.amount,
                    },
                ]
        }

        return output
    }

    const amountFormatted = (tx: SCAPI.Transaction & TransactionJSON) => {
        const sumInfo = boxesSums(tx, symbol)
        const sum = sumInfo.output
        const amount = bigNumberFormat(sum, precision!)

        return amount.concat(" " + sumInfo.symbol)
    }

    const summaryFields = (transaction: SCAPI.Transaction & TransactionJSON) => {
        const blockInfo = transaction.blockHeight === -1
                ? {
                    title: "Block",
                    value: `Pending (${ transaction.confirmations } confirmations)`,
                    type: InfoType.text,
                }
                : {
                    title: "Block",
                    value: {
                        text: transaction.blockHash ?? "-",
                        link: transaction.blockHash
                                ? URLProvider.URL_BLOCK_DETAIL.replace(
                                        ":blockId",
                                        transaction.blockHash
                                )
                                : "-",
                        additionalInfo: `(${ transaction.confirmations } confirmations)`,
                    },
                    type: InfoType.linkExtended,
                }

        let temp: InfoItem[] = [
            {
                title: "Timestamp",
                value: transaction.blockTime!,
                type: InfoType.date,
            },
            {
                title: "Status",
                value: {
                    value: transaction.blockHeight === -1 ? "Pending" : "Confirmed",
                    color: transaction.blockHeight === -1 ? TextColouredOption.yellow : TextColouredOption.green
                },
                type: InfoType.textColoured,
            },
            blockInfo,
            {
                title: "Transaction Fee",
                value: {
                    amount: transaction.fee,
                    currency: Constants.ZENSymbol,
                    precision: 8,
                },
                type: InfoType.amount,
            },
        ]

        if (
                transaction.typeName !==
                TokenChainTransactionTypes.TokenTypeDeclareTransaction
        ) {
            temp.push({
                title: "Amount",
                value: amountFormatted(transaction),
                type: InfoType.text,
            })
        }

        temp.push({
            title: "Transaction type name",
            value: transaction.typeName,
            type: InfoType.text,
        })

        return temp
    }

    return (
            <div>
                <main>
                    <div className="max-w-screen-xl ml-auto mr-auto xl:grid">
                        <>
                            <div className="px-8 sm:pb-8 space-y-16 mt-20">
                                <>
                                    { (isLoading || tokenIsLoading) && (
                                            <div className="w-20 mx-auto">
                                                <TokenMintSpinner/>
                                            </div>
                                    ) }
                                    { error && (
                                            <div
                                                    className="flex justify-center items-center text-2xl text-white my-40 font-bold">
                                                <h1>
                                                    Sorry, we couldn&apos;t find the transaction you&apos;re
                                                    looking for.
                                                </h1>
                                            </div>
                                    ) }
                                    { transaction && !tokenIsLoading && (
                                            <div>
                                                <p className="font-bold text-white text-4xl mb-1 mr-4">
                                                    Transaction
                                                </p>
                                                <div className="inline-flex mt-2">
                                                    <p className="text-Gray_text text-lg mr-2 whitespace-nowrap">
                                                        Transaction ID:
                                                    </p>
                                                    <p className="text-white text-lg">{ transaction.txid }</p>
                                                    <button data-event="click" className="ml-6">
                                                        <img
                                                                data-tip="Transaction ID copied to the pasteboard!"
                                                                data-for="description"
                                                                data-background-color="#041742"
                                                                className="h-7 copy-icon"
                                                                src={ copyIcon }
                                                        />
                                                        <ReactTooltip
                                                                id="description"
                                                                effect="solid"
                                                                multiline
                                                                event="click"
                                                                afterShow={ async () => {
                                                                    navigator.clipboard.writeText(transaction.txid)
                                                                    await delay(2000)
                                                                    ReactTooltip.hide()
                                                                } }
                                                        />
                                                    </button>
                                                </div>
                                                <div className="mt-10">
                                                    <div className="inline-flex">
                                                        <p className="font-bold text-white text-2xl mb-4 mr-4">
                                                            Summary
                                                        </p>
                                                        <InfoToolTip
                                                                title="summary"
                                                                info={ <p>Basic info about the transaction.</p> }
                                                        />
                                                    </div>
                                                    <InfoBlock fields={ summaryFields(transaction) }/>
                                                </div>
                                            </div>
                                    ) }
                                </>
                            </div>

                            { transaction && !tokenIsLoading && (
                                    <div className="px-8 sm:pb-8 space-y-16 mt-6">
                                        <div>
                                            { transaction.vin && transaction.vin.length > 0 && (
                                                    <>
                                                        <div className="inline-flex">
                                                            <p className="font-bold text-white text-2xl mb-4 mr-4">
                                                                Input Boxes
                                                            </p>
                                                            <InfoToolTip
                                                                    title="input-boxes"
                                                                    info={
                                                                        <p>Required input/s spent for the
                                                                            transaction.</p>
                                                                    }
                                                            />
                                                        </div>
                                                        { transaction.vin.map(
                                                                (box: SCAPI.Box, index: number) => {
                                                                    return (
                                                                            <div className={ index === 0 ? "" : "mt-8" }>
                                                                                <InfoBlock
                                                                                        fields={ fieldsForBox(box) }/>
                                                                            </div>
                                                                    )
                                                                }
                                                        ) }
                                                    </>
                                            ) }

                                            { transaction.vout && transaction.vout.length > 0 && (
                                                    <>
                                                        <div className="inline-flex mt-14">
                                                            <p className="font-bold text-white text-2xl mb-4 mr-4">
                                                                Output Boxes
                                                            </p>
                                                            <InfoToolTip
                                                                    title="output-boxes"
                                                                    info={ <p>Output/s produced by the
                                                                        transaction. </p> }
                                                            />
                                                        </div>
                                                        { transaction.vout.map(
                                                                (box: SCAPI.Box, index: number) => {
                                                                    return (
                                                                            <div className={ index === 0 ? "" : "mt-8" }>
                                                                                <InfoBlock
                                                                                        fields={ fieldsForBox(box) }/>
                                                                            </div>
                                                                    )
                                                                }
                                                        ) }
                                                    </>
                                            ) }
                                        </div>
                                    </div>
                            ) }
                        </>
                    </div>
                </main>
            </div>
    )
}

export default TransactionDetail
