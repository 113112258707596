import React, { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { Link, useNavigate, useParams } from "react-router-dom"
import InfoBlock, { InfoItem, InfoType } from "../components/InfoBlock/InfoBlock"
import ListContainer from "../components/ListContainer/ListContainer"
import ListHeader from "../components/ListHeader/ListHeader"
import arrowCollapse from "../assets/green_arrow_left.png"
import { getBlockBy, getTransactionsByBlockHashOrHeight, getTransactionsByToken } from "../network/APIRepository"
import { Constants } from "../utils/Constants"
import { URLProvider } from "../utils/URLProvider"
import { bigNumberFormat, getElapsedTime } from "../utils/Utils"
import TokenMintSpinner from "../templates/TokenMintSpinner";

const BlockDetail: React.FC = () => {

    const navigate = useNavigate()
    const params = useParams()
    const {blockId} = params

    const [currentPage, setCurrentPage] = useState<number>(0)
    const [totalPages, setTotalPages] = useState<number | undefined>(undefined)
    const {
        data: block,
        error,
        isLoading
    } = useQuery<any>(["getBlockBy", blockId], () => getBlockBy(blockId as string), {
        retry: 1
    })

    const {
        data: txs,
        error: txsError,
        isLoading: txsIsLoading
    } = useQuery<any>(["getTransactionsByToken", blockId], () => getTransactionsByBlockHashOrHeight(blockId as string), {})

    useEffect(() => {
        if (txs) {
            setTotalPages(txs[1])
        }

    }, [txs])

    const titleSection = () => {
        return (
                <ListHeader
                        title="Transactions in Block"
                        currentPage={ currentPage + 1 }
                        totalPages={ txs ? txs[1] === 0 ? 1 : txs[1] : undefined }
                        hasPreviousPage={ currentPage > 0 }
                        onPreviousClick={ () => {
                            setCurrentPage(currentPage - 1)
                        } }
                        onFirstClick={ () => {
                            setCurrentPage(0)
                        } }
                        onLastClick={ () => {
                            if ((totalPages && currentPage === totalPages - 1) ||
                                    totalPages === 0) {
                                return
                            }

                            setCurrentPage(totalPages! - 1)
                        } }
                        hasNextPage={ totalPages ? totalPages > currentPage + 1 : false }
                        onNextClick={ () => {
                            setCurrentPage(currentPage + 1)
                        } }/>
        )
    }

    const optionalDetailFields = (): InfoItem[] => {
        let output: InfoItem[] = []
        if (block.ommersMerkleRootHash !== "0000000000000000000000000000000000000000000000000000000000000000") {
            output.push(
                    {
                        title: "Ommers Merkle Root Hash",
                        value: block.ommersMerkleRootHash,
                        type: InfoType.text
                    }
            )
        }
        if (block.sidechainTransactionsMerkleRootHash !== "0000000000000000000000000000000000000000000000000000000000000000") {
            output.push(
                    {
                        title: "Sidechain Transactions Merkle Root Hash",
                        value: block.sidechainTransactionsMerkleRootHash,
                        type: InfoType.text
                    }
            )
        }

        return output
    }

    return (
            <div>
                <main>
                    <div className="max-w-screen-xl ml-auto mr-auto xl:grid">
                        <div className="px-8 sm:pb-8 space-y-16 mt-20">
                            { isLoading && (
                                    <div className="w-20 mx-auto">
                                        <TokenMintSpinner/>
                                    </div>
                            ) }
                            { error && (
                                    <div className="flex justify-center items-center text-2xl text-white my-40 font-bold">
                                        <h1>
                                            Sorry, we couldn&apos;t find the block you&apos;re looking for.
                                        </h1>
                                    </div>
                            ) }
                            { block && (
                                    <div>
                                        <div className="inline-flex">
                                            <p className="font-bold text-white text-4xl mb-1 mr-4">Block
                                                #{ block.height }</p>
                                            { block.previousBlockHash && block.height > 1 && (
                                                    <button
                                                            onClick={ () => {
                                                                navigate(URLProvider.URL_BLOCK_DETAIL.replace(":blockId", block.previousBlockHash))
                                                            } }
                                                            className="text-ZBF_green h-full col-span-1 ml-8 my-auto">
                                                        <img className="pagination-arrow h-2/4 object-contain"
                                                             src={ arrowCollapse }/>
                                                    </button>
                                            ) }
                                            { block.nextBlockHash && (
                                                    <button
                                                            onClick={ () => {
                                                                navigate(URLProvider.URL_BLOCK_DETAIL.replace(":blockId", block.nextBlockHash))
                                                            } }
                                                            className="text-ZBF_green h-full col-span-1 ml-8 my-auto">
                                                        <img className="pagination-arrow rotate-180 h-2/4 object-contain"
                                                             src={ arrowCollapse }/>
                                                    </button>
                                            ) }
                                        </div>
                                        <div className="mt-8">
                                            <InfoBlock fields={
                                                [
                                                    {
                                                        title: "Block Hash",
                                                        value: block.hash,
                                                        type: InfoType.text
                                                    },
                                                    {
                                                        title: "Timestamp",
                                                        value: new Date(block.time),
                                                        type: InfoType.date
                                                    },
                                                    {
                                                        title: "Number of transactions",
                                                        value: block.transactionCount,
                                                        type: InfoType.text
                                                    },
                                                    {
                                                        title: "Number of confirmations",
                                                        value: block.confirmations,
                                                        type: InfoType.text
                                                    },
                                                    {
                                                        title: "Ommers Cumulative Score",
                                                        value: block.ommersCumulativeScore,
                                                        type: InfoType.text
                                                    },
                                                    {
                                                        title: "Signature",
                                                        value: block.signature.signature,
                                                        type: InfoType.text
                                                    },
                                                    ...optionalDetailFields()
                                                ]
                                            }/>
                                        </div>
                                    </div>
                            ) }
                        </div>

                        { !error && (
                                <div className="px-8 sm:pb-8 space-y-16 mt-20">
                                    <ListContainer
                                            titleSection={ titleSection() }
                                            elements={ ["Transaction ID", "Age", "Type Name", "Fee"] }
                                            elementsConfig={ ["1/4", "1/4", "1/4", "1/4"] }
                                            isLoading={ txsIsLoading }
                                            isEmpty={ txs && txs[0].length === 0 }
                                            tableContent={
                                                txs ? txs[0].map((b: any, index: number) => {
                                                    return (
                                                            <tr key={ b.txid }
                                                                className={ (index === txs[0].length - 1 ? "" : "border-b").concat(" border-Gray_text h-12") }>
                                                                <td className="text-sm pr-8 truncate">
                                                                    <Link
                                                                            className="hover:underline"
                                                                            to={ URLProvider.URL_TRANSACTION_DETAIL.replace(
                                                                                    ":txId",
                                                                                    b.txid
                                                                            ) }
                                                                    >
                                                                        { b.txid }
                                                                    </Link>
                                                                </td>

                                                                <td className="text-sm pr-8 text-white whitespace-nowrap truncate">{ getElapsedTime(b.blockTime!) }</td>
                                                                <td className="text-sm pr-8 text-white truncate">{ b.typeName }</td>
                                                                <td className="text-sm text-white text-right truncate">
                                                            <span
                                                                    className="inline">{ b.fee || b.fee === 0
                                                                    ? bigNumberFormat(b.fee, Constants.ZENPrecision)
                                                                    : "-" } </span>
                                                                    <span className="inline font-bold">ZEN</span>
                                                                </td>
                                                            </tr>
                                                    )
                                                }) : <></>
                                            }
                                    >
                                    </ListContainer>
                                </div>
                        ) }
                    </div>
                </main>
            </div>
    )
}

export default BlockDetail
