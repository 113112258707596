import React from "react"
import HomeInfo from "../../components/HomeInfo/HomeInfo"
import HomeSidebar from "../../components/HomeSidebar/HomeSidebar"

const Home = ({tx}: any | undefined) => {
    return (
        <div>
            <main>
                <div className="grid-cols-3 max-w-screen-xl ml-auto mr-auto xl:grid">
                    <HomeInfo tx={ tx }/>
                    <HomeSidebar/>
                </div>
            </main>
        </div>
    )
}

export default Home
