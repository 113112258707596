import React, { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"
import ReactTooltip from "react-tooltip"
import copyIcon from "../assets/copy_icon.png"
import ListHeader from "../components/ListHeader/ListHeader"
import TokenDetailInfo from "../components/TokenDetailInfo"
import { getNFTCollections, getNFTsByCollection } from "../network/APIRepository"
import { SCAPI } from "../network/explorer_types/SCAPI"
import { delay, getIPFSFinalURL } from "../utils/Utils"
import NFTsList from "../components/NFTsList";
import TokenMintSpinner from "../templates/TokenMintSpinner";

const NFTCollectionDetail: React.FC = () => {
    const params = useParams()
    const {collectionId} = params

    const [currentPage, setCurrentPage] = useState<number>(0)
    const [totalPages, setTotalPages] = useState<number | undefined>(undefined)
    const {
        data: collection,
        error,
        isLoading
    } = useQuery<[Array<SCAPI.TokenBoxType>, number] | void>(["getCollection", collectionId], () => getNFTCollections(0, undefined, collectionId))

    const {
        data: nfts,
        error: nftsError,
        isLoading: nftsIsLoading
    } = useQuery<[Array<SCAPI.TokenNonFungibleBoxType>, number] | void>(["getNFTsByCollection", collectionId, currentPage], () => getNFTsByCollection(currentPage, collectionId as string, undefined))

    useEffect(() => {
        if (nfts) {
            setTotalPages(nfts[1])
        }
    }, [nfts])

    const titleSection = () => {
        return (
                <ListHeader
                        title="NFTs"
                        currentPage={ currentPage + 1 }
                        totalPages={ nfts ? nfts[1] === 0 ? 1 : nfts[1] : undefined }
                        hasPreviousPage={ currentPage > 0 }
                        onPreviousClick={ () => {
                            setCurrentPage(currentPage - 1)
                        } }
                        onFirstClick={ () => {
                            setCurrentPage(0)
                        } }
                        onLastClick={ () => {
                            if ((totalPages && currentPage === totalPages - 1) ||
                                    totalPages === 0) {
                                return
                            }

                            setCurrentPage(totalPages! - 1)
                        } }
                        hasNextPage={ totalPages ? totalPages > currentPage + 1 : false }
                        onNextClick={ () => {
                            setCurrentPage(currentPage + 1)
                        } }/>
        )
    }

    return (
            <div>
                <main>
                    <div className="max-w-screen-xl ml-auto mr-auto xl:grid">
                        <>
                            <div className="px-8 sm:pb-8 space-y-16 mt-20">
                                <>
                                    { isLoading && (
                                            <div className="w-20 mx-auto">
                                                <TokenMintSpinner/>
                                            </div>
                                    ) }
                                    { collection && collection[0].length === 0 && (
                                            <div
                                                    className="flex justify-center items-center text-2xl text-white my-40 font-bold">
                                                <h1>
                                                    Sorry, we couldn&apos;t find the token you&apos;re looking for.
                                                </h1>
                                            </div>
                                    ) }
                                    { collection && collection[0].length > 0 && (
                                            <div>
                                                <div className="flex mt-2">
                                                    <p className="font-bold text-white text-4xl mb-1 mr-6">{ collection[0][0].name }</p>
                                                    <p className="font-bold text-white text-xl mt-3">({ collection[0][0].symbol.toUpperCase() })</p>
                                                </div>
                                                <div className="inline-flex mt-2">
                                                    <p className="text-Gray_text text-lg mr-2">Collection Unique ID:</p>
                                                    <p className="text-white text-lg">{ collection[0][0].uuid }</p>
                                                    <button data-event="click" className="ml-6">
                                                        <img
                                                                data-tip="Collection Unique ID copied to the pasteboard!"
                                                                data-for="description"
                                                                data-background-color="#041742"
                                                                className="h-7 copy-icon" src={ copyIcon }/>
                                                        <ReactTooltip
                                                                id="description"
                                                                effect="solid"
                                                                multiline
                                                                event="click"
                                                                afterShow={ async () => {
                                                                    navigator.clipboard.writeText(collection[0][0].uuid)
                                                                    await delay(2000)
                                                                    ReactTooltip.hide()
                                                                } }
                                                        />
                                                    </button>
                                                </div>
                                                <div className="mt-8">
                                                    <TokenDetailInfo token={ collection[0][0] } isNFTCollection/>
                                                </div>
                                            </div>
                                    ) }
                                </>
                            </div>

                            { collection && collection[0].length > 0 && (
                                    <div className="px-8 sm:pb-8 space-y-16 mt-10">
                                        <NFTsList isLoading={ nftsIsLoading }
                                                  isEmpty={ nfts !== undefined && nfts[0].length === 0 }
                                                  baseURI={ getIPFSFinalURL(collection[0][0].baseURI) }
                                                  collectionSymbol={ collection[0][0].symbol }
                                                  titleSection={ titleSection() }
                                                  filteredNFTs={ nfts ? nfts[0].filter((nft) => nft.mintHeight! > -1) : [] }/>

                                    </div>
                            ) }
                        </>
                    </div>
                </main>
            </div>
    )
}

export default NFTCollectionDetail
