import React from "react"
import explorerLogo from "../assets/explorer_logo_alpha.svg"
import NavContainer from "../templates/NavContainer"
import FooterSubheading from "./Footer/FooterSubheading"
import Links from "./Footer/Links"
import SocialNetworks from "./SocialNetworks/SocialNetworks"

const AppFooter = () => {
    return (
        <footer className="bg-Footer_bckgrnd text-white bottom-12 w-full mt-auto">
            <NavContainer>
                <div className="pb-12 pt-14">
                    <div className="sm:flex sm:justify-between">
                        <div className="flex">
                            <div className="mr-20 hidden sm:block">
                                <a href={`/`}>
                                    <img
                                        className="mb-12 h-8"
                                        src={ explorerLogo }
                                        alt={ "Explorer Logo" }
                                    />
                                </a>
                            </div>
                            <Links />
                        </div>
                        <div className="hidden lg:block mt-0 w-[246px]">
                            <FooterSubheading>
                                Join Our Community
                            </FooterSubheading>
                            <SocialNetworks />
                        </div>
                    </div>
                    <div className="lg:hidden my-10 md:flex md:justify-end">
                        <div>
                            <FooterSubheading>
                                Join Our Community
                            </FooterSubheading>
                            <SocialNetworks />
                        </div>
                    </div>
                    <div className="sm:hidden flex justify-center">
                        <a href="/">
                            <img
                                className="mb-12 h-8"
                                src={ explorerLogo }
                                alt={ "Horizen Logo" }
                            />
                        </a>
                    </div>
                </div>
            </NavContainer>
        </footer>
    )
}

export default AppFooter