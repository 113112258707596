export class APIURLProvider {
    public static baseUrl = process.env.REACT_APP_BACKEND_URL!
    public static socketUrl = process.env.REACT_APP_BACKEND_SOCKET!

    public static URL_BLOCKS = `/blocks`
    public static URL_BLOCK_BY_HASH = `/block/hash/:hash`
    public static URL_BLOCK_BY_HEIGHT = `/block/height/:height`

    public static URL_TX_BY_ID = `/tx/:txId`
    public static URL_TXS_BY_BLOCK_HASH = `/txs/blockHash/:blockHash`
    public static URL_TXS_BY_BLOCK_HEIGHT = `/txs/blockHeight/:blockHeight`

    public static URL_STATUS = `/status`
    public static URL_TOKENS = `/tokens`
    public static URL_TRANSACTIONS = `/txs`
    public static URL_TRANSACTIONS_BY_TOKEN = `/tokens/txs/token/:uuid`
    public static URL_TRANSACTIONS_BY_ADDRESS = `/txs/address/:address`
    public static URL_BALANCE_BY_ADDRESS = `/tokens/balance/address/:address`

    public static URL_NFTs = `/nfts`
    public static URL_NFTs_BOXES = `/nfts/boxes`
    public static URL_NFT_TXS = `/nfts/txs/token/:tokenUuid`
}