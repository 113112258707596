export class URLProvider {
    public static URL_HOME = "/"
    public static URL_BLOCKS = "/blocks"
    public static URL_BLOCKS_DATE = "/blocks/:blockDate"
    public static URL_BLOCK_DETAIL = "/block/:blockId"
    public static URL_TRANSACTIONS = "/txs"
    public static URL_TRANSACTIONS_DATE = "/txs/:txDate"
    public static URL_TRANSACTION_DETAIL = "/tx/:txId"
    public static URL_STATUS = "/status"
    public static URL_ADDRESS_DETAIL = "/address/:address"
    public static URL_TOKENS = "/tokens"
    public static URL_TOKENS_SEARCH = "/tokens/:tokenSearch"
    public static URL_TOKEN_DETAIL = "/token/:tokenId"
    public static URL_NFT_DETAIL = "/nft/:collectionId/:serialNumber"
    public static URL_COLLECTION_DETAIL = "/collection/:collectionId"
    public static URL_NFT_COLLECTIONS = "/collections"
    public static URL_NFT_COLLECTIONS_SEARCH = "/collections/:tokenSearch"
    public static URL_CONTACTS = "/contact"
    public static URL_VERSION = "/version"
}

export function detectRoute(url: string): string {
    const urlComponents = url.split("/")
    return Object.values(URLProvider).find((urlProvided) => {
        const routeComp = urlProvided.split("/")
        return routeComp[ 1 ] === urlComponents[ 1 ] && routeComp.length === urlComponents.length
    })
}
