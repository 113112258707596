import React from "react"
import { Link } from "react-router-dom"
import { SCAPI } from "../network/explorer_types/SCAPI"
import { URLProvider } from "../utils/URLProvider"
import { bigNumberFormat, maxSupplyDisplay, splitStringMiddle } from "../utils/Utils"

export type TokenDetailInfoProps = {
    token: SCAPI.TokenBoxType
    isNFTCollection: boolean
}

const TokenDetailInfo: React.FC<TokenDetailInfoProps> = (
        {
            token,
            isNFTCollection
        }
) => {
    const beforeAfterCssRules = `relative after:absolute before:absolute
    after:w-4 before:w-4 after:h-full before:h-full after:border before:border
    after:top-0 before:top-0 after:right-0 before:left-0
    after:border-l-0 before:border-r-0
    after:border-white before:border-white`

    return (
            <div className="w-full">
                <div className={ `${ beforeAfterCssRules } h-[12rem] text-white font-bold grid-cols-2 grid` }>
                    <div className="col-span-1 grid-cols-2 h-full grid py-8 px-10">
                        <div className="col-span-1 grid-rows-2 grid space-y-2">
                            <div data-testid={"circulating_supply_container"}
                                 className="row-span-1 truncate">
                                <p className="text-base text-Gray_text">Circulating Supply</p>
                                <p className="text-base text-white truncate"
                                   title={ bigNumberFormat(token.currentSupply, token.precision) }>{ bigNumberFormat(token.currentSupply, token.precision) }</p>
                            </div>
                            <div data-testid={"total_supply_container"}
                                 className="row-span-1 truncate">
                                <p className="text-base text-Gray_text">Total Supply</p>
                                <p className="text-base text-white truncate"
                                   title={ maxSupplyDisplay(token.maxSupply, token.precision) }>{ maxSupplyDisplay(token.maxSupply, token.precision) }</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1 grid-cols-2 h-full grid py-8 px-10">
                        <div className="col-span-1 grid-rows-2 grid space-y-2">
                            <div data-testid={"owner_container"}
                                 className="row-span-1">
                                <p className="text-base text-Gray_text mt-auto">{ isNFTCollection ? "Creator" : "Owner" }</p>
                                <Link
                                        className="hover:underline font-normal text-base mb-auto"
                                        to={ URLProvider.URL_ADDRESS_DETAIL.replace(
                                                ":address",
                                                token.owner ?? ""
                                        ) }
                                >
                                    { splitStringMiddle(token.owner ?? "", 50) }
                                </Link>
                            </div>
                            { isNFTCollection && (
                                    <div className="row-span-1 overflow-x-hidden">
                                        <p className="text-base text-Gray_text">Base URI</p>
                                        <p className="text-base text-white truncate"
                                           title={ token.baseURI }>{ token.baseURI }</p>
                                    </div>
                            ) }
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default TokenDetailInfo