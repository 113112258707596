import React from "react"
import { useQuery } from "react-query"
import InfoBlock, { InfoType } from "../../components/InfoBlock/InfoBlock"
import { getStatus } from "../../network/APIRepository"
import { URLProvider } from "../../utils/URLProvider"

const Status: React.FC = () => {
    const {
        data,
        error,
        isLoading
    } = useQuery<any>("getStatus", () => getStatus())

    return (
        <div>
            <main>
                <div className="max-w-screen-xl ml-auto mr-auto xl:grid">
                    <div className="px-8 sm:pb-8 space-y-16 mt-20">
                        { isLoading && (
                            <></>
                        ) }
                        { data && (
                            <div>
                                <p className="font-bold text-white text-4xl mb-1 mr-4">Application Status</p>
                                <div className="mt-10 grid grid-cols-2 space-x-8">
                                    <div className="col-span-1">
                                        <p className="font-bold text-white text-2xl mb-4 mr-4">Sync Status</p>
                                        <InfoBlock fullBorder fields={
                                            [
                                                {
                                                    title: "Current Sync Status",
                                                    value: data.currentSyncStatus,
                                                    type: InfoType.text
                                                },
                                                {
                                                    title: "Last Block Hash Synced",
                                                    value: {
                                                        text: data.lastBlockHashSynced,
                                                        link: URLProvider.URL_BLOCK_DETAIL.replace(":blockId", data.lastBlockHashSynced)
                                                    },
                                                    type: InfoType.link
                                                },
                                                {
                                                    title: "Last Block Height Synced",
                                                    value: data.lastBlockHeightSynced,
                                                    type: InfoType.text
                                                }
                                            ]
                                        }/>
                                    </div>
                                    <div className="col-span-1">
                                        <p className="font-bold text-white text-2xl mb-4 mr-4">TokenMint sidechain node
                                            Information</p>
                                        <InfoBlock fullBorder fields={
                                            [
                                                {
                                                    title: "Blocks",
                                                    value: data.blocksNumber,
                                                    type: InfoType.text
                                                },
                                                {
                                                    title: "Network",
                                                    value: process.env.REACT_APP_ENV,
                                                    type: InfoType.text
                                                }
                                            ]
                                        }/>
                                    </div>
                                </div>
                            </div>
                        ) }
                    </div>
                </div>
            </main>
        </div>
    )
}

export default Status
