import React, { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { Link, useNavigate } from "react-router-dom"
import { getBlocks, getTransactions } from "../../network/APIRepository"
import { SCAPI } from "../../network/explorer_types/SCAPI"
import { URLProvider } from "../../utils/URLProvider"
import { getElapsedTime } from "../../utils/Utils"
import ListContainer from "../ListContainer/ListContainer"
import ListHeader from "../ListHeader/ListHeader"
import SolidButton from "../SolidButton";

const HomeInfo: React.FC<any> = ({tx}: any | undefined) => {

    const navigate = useNavigate()
    const [txs, setTxs] = useState<Array<SCAPI.Transaction>>([])

    const {
        data: blocks,
        error,
        isLoading
    } = useQuery<any>("getBlocks", () => getBlocks(undefined, 5, undefined, undefined, undefined))

    const {
        data: initialMempoolTxs,
        error: txsMempoolError,
        isLoading: txsMempoolLoading
    } = useQuery<any>(["getTransactions", "mempool"], () => getTransactions(true, undefined, undefined, 5), {cacheTime: 10000})

    const {
        data: initialNonMempoolTxs,
        error: txsNonMempoolError,
        isLoading: txsNonMempoolLoading
    } = useQuery<any>(["getTransactions", "nonmempool"], () => getTransactions(false, undefined, undefined, 5), {
        enabled: initialMempoolTxs ? initialMempoolTxs[0].length < 5 : false,
        cacheTime: 10000
    })

    useEffect(() => {
        let newTxs: SCAPI.Transaction[] = []
        if (initialMempoolTxs && initialMempoolTxs[0].length > 0) {
            newTxs = [...initialMempoolTxs[0]]
        }

        if (initialNonMempoolTxs && initialNonMempoolTxs[0].length > 0 && newTxs.length < 5) {
            const placesToFill = 5 - newTxs.length
            for (let i = 0; i < placesToFill; i++) {
                if (i < initialNonMempoolTxs[0].length) {
                    newTxs = [...newTxs, initialNonMempoolTxs[0][i]]
                }
            }
        }

        setTxs(newTxs)
    }, [initialMempoolTxs, initialNonMempoolTxs])

    useEffect(() => {
        if (tx && !txs.some((element) => element.txid === tx.txid)) {
            const newTxs = [tx, ...txs]
            if (newTxs.length > 10) {
                newTxs.pop()
            }
            setTxs(newTxs)
        }
    }, [tx])

    return (
            <div className="col-span-2 px-8 sm:pb-8 space-y-16 mt-20">
                <ListContainer
                        titleSection={
                            <ListHeader
                                    title="Latest Blocks"
                            />
                        }
                        elements={ ["Block", "Age", "Transactions"] }
                        elementsConfig={ ["1/4", "2/4", "1/4"] }
                        isLoading={ isLoading }
                        isEmpty={ blocks ? blocks[0].length === 0 : !isLoading }
                        tableContent={
                            blocks ? blocks[0].map((b: SCAPI.BlockJSON) => {
                                return (
                                        <tr key={ b.hash }
                                            className="border-b border-Gray_text h-12">
                                            <td className="text-sm pr-8">
                                                <Link
                                                        className="hover:underline"
                                                        to={ URLProvider.URL_BLOCK_DETAIL.replace(
                                                                ":blockId",
                                                                b.height!.toString()
                                                        ) }
                                                >
                                                    { b.height }
                                                </Link>
                                            </td>

                                            <td className="text-sm pr-8 text-white whitespace-nowrap truncate">{ getElapsedTime(b.timeNormalized!) }</td>
                                            <td className="text-sm text-right text-white truncate">{ b.transactionCount }</td>
                                        </tr>
                                )
                            }) : <div/>
                        }
                >
                    <div className="mx-auto table mt-8 mb-4">
                        <SolidButton label="SEE ALL BLOCKS" onClick={ () => navigate(URLProvider.URL_BLOCKS) }/>
                    </div>
                </ListContainer>

                <ListContainer
                        titleSection={
                            <ListHeader
                                    title="Latest Transactions"
                            />
                        }
                        elements={ ["Transaction ID", "Type name"] }
                        elementsConfig={ ["1/2", "1/2"] }
                        isLoading={ txsNonMempoolLoading || txsMempoolLoading }
                        isEmpty={ txs ? txs.length === 0 : false }
                        tableContent={
                            txs.map((b: SCAPI.Transaction, index: number) => {
                                return (
                                        <tr key={ b.txid }
                                            className="border-b border-Gray_text h-12">
                                            <td className="text-sm pr-8 truncate">
                                                <Link
                                                        className="hover:underline"
                                                        to={ URLProvider.URL_TRANSACTION_DETAIL.replace(
                                                                ":txId",
                                                                b.txid
                                                        ) }
                                                >
                                                    { b.txid }
                                                </Link>
                                            </td>
                                            <td className="text-sm text-right text-white truncate">{ b.typeName }</td>
                                        </tr>
                                )
                            })
                        }
                >
                    <div className="mx-auto table mt-8 mb-4">
                        <SolidButton label="SEE ALL TRANSACTIONS"
                                     onClick={ () => navigate(URLProvider.URL_TRANSACTIONS) }/>
                    </div>
                </ListContainer>

            </div>
    )
}

export default HomeInfo
