import React, { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { Link, useParams } from "react-router-dom"
import ReactTooltip from "react-tooltip"
import copyIcon from "../assets/copy_icon.png"
import ListContainer from "../components/ListContainer/ListContainer"
import ListHeader from "../components/ListHeader/ListHeader"
import { getBalanceByAddress, getNFTsByAddress, getTransactionsByAddress } from "../network/APIRepository"
import { SCAPI } from "../network/explorer_types/SCAPI"
import { Balance } from "../network/explorer_types/TokenchainAPI"
import { getTokenSymbolAndPrecision, TransactionJSON } from "../network/explorer_types/Transaction"
import { Constants } from "../utils/Constants"
import { URLProvider } from "../utils/URLProvider"
import { bigNumberFormat, boxesSums, delay, getElapsedTime, splitStringMiddle } from "../utils/Utils"
import AddressDetailInfo from "../components/AddressDetailInfo";
import TokenMintSpinner from "../templates/TokenMintSpinner";

export type NFT = {
    serialNumber: string
    collectionUuid: string
    collectionName: string
    collectionSymbol: string
    owner: string
    creator: string
}

const AddressDetail: React.FC = () => {
    const params = useParams()
    const {address} = params

    const [currentPage, setCurrentPage] = useState<number>(0)
    const [totalPages, setTotalPages] = useState<number | undefined>(undefined)
    const [totalTransactions, setTotalTransactions] = useState<string | undefined>(undefined)
    const {
        data: txs,
        error: txsError,
        isLoading: txsIsLoading
    } = useQuery<any>(["getTransactionsByAddress", address as string, currentPage], () => getTransactionsByAddress(address as string, true, currentPage))

    const {
        data: balances,
        error: balanceError,
        isLoading: balanceIsLoading
    } = useQuery<Balance[] | void>(["getBalanceByAddress", address as string], () => getBalanceByAddress(address as string), {staleTime: 0})

    const {
        data: nfts,
        error: nftsError,
        isLoading: nftsIsLoading
    } = useQuery<NFT[] | void>(["getNFTsByAddress", address as string], () => getNFTsByAddress(address as string))

    useEffect(() => {
        if (txs) {
            setTotalPages(txs[1])
            if (Number(txs[1]) === 0) {
                setTotalTransactions("0")
            } else if (currentPage + 1 === txs[1]) {
                setTotalTransactions(txs[0].length + currentPage * 20)
            } else {
                const currentNumber = (Number(txs[1]) - 1) * 20
                if (totalTransactions) {
                    if (Number(totalTransactions) < currentNumber) {
                        setTotalTransactions(currentNumber.toString().concat("+"))
                    }
                } else {
                    setTotalTransactions(currentNumber.toString().concat("+"))
                }
            }
        }

    }, [txs])

    const titleSection = () => {
        return (
                <ListHeader
                        title="Transactions"
                        currentPage={ currentPage + 1 }
                        totalPages={ txs ? txs[1] === 0 ? 1 : txs[1] : undefined }
                        hasPreviousPage={ currentPage > 0 }
                        onPreviousClick={ () => {
                            setCurrentPage(currentPage - 1)
                        } }
                        onFirstClick={ () => {
                            setCurrentPage(0)
                        } }
                        onLastClick={ () => {
                            if ((totalPages && currentPage === totalPages - 1) ||
                                    totalPages === 0) {
                                return
                            }

                            setCurrentPage(totalPages! - 1)
                        } }
                        hasNextPage={ totalPages ? totalPages > currentPage + 1 : false }
                        onNextClick={ () => {
                            setCurrentPage(currentPage + 1)
                        } }/>
        )
    }

    const amountFormatted = (tx: SCAPI.Transaction & TransactionJSON) => {
        const [txSymbol, txPrecision] = getTokenSymbolAndPrecision(tx, balances?.map((balance) => {
            return {symbol: balance.symbol, uuid: balance.uuid, precision: balance.precision}
        }))

        const sumInfo = boxesSums(tx, txSymbol)
        const sum = sumInfo.output
        const amount = bigNumberFormat(sum, txPrecision)

        return (
                <td className="text-sm pr-8 text-white truncate" title={ amount.concat(" " + sumInfo.symbol) }>
                    <span className="inline">{ amount } </span>
                    <span
                            className="inline font-bold">{ sumInfo.symbol }</span>
                </td>
        )
    }

    const nftsEnabled = process.env.REACT_APP_NFTs_ENABLED ? process.env.REACT_APP_NFTs_ENABLED === "true" : false

    return (
            <div>
                <main>
                    <div className="max-w-screen-xl ml-auto mr-auto xl:grid">
                        <div className="px-8 sm:pb-8 space-y-16 mt-20">
                            <div>
                                { txs && txs[0].length > 0 && (
                                        <>
                                            <p className="font-bold text-white text-4xl mb-1 mr-4">Address</p>
                                            <div className="inline-flex mt-2">
                                                <p className="text-Gray_text text-lg mr-2 whitespace-nowrap">Full
                                                    Address:</p>
                                                <p className="text-white text-lg">{ address }</p>
                                                <button data-event="click" className="ml-6">
                                                    <img
                                                            data-tip="Address copied to the pasteboard!"
                                                            data-for="description"
                                                            data-background-color="#041742"
                                                            className="h-7 copy-icon" src={ copyIcon }/>
                                                    <ReactTooltip
                                                            id="description"
                                                            effect="solid"
                                                            multiline
                                                            event="click"
                                                            afterShow={ async () => {
                                                                navigator.clipboard.writeText(address as string)
                                                                await delay(2000)
                                                                ReactTooltip.hide()
                                                            } }
                                                    />
                                                </button>
                                            </div>
                                        </>
                                ) }
                                { txsIsLoading && (
                                        <div className="w-12 h-12 my-12 mx-auto">
                                            <TokenMintSpinner/>
                                        </div>
                                ) }
                                { txs && txs[0].length === 0 && (
                                        <div className="flex justify-center items-center text-2xl text-white my-40 font-bold">
                                            <h1>
                                                Sorry, we couldn&apos;t find any transactions for that address.
                                            </h1>
                                        </div>
                                ) }
                                { txsError && (
                                        <div className="grid text-center justify-center items-center text-2xl text-white my-40 font-bold">
                                            <h1>{ `Sorry, we couldn't find any transactions for that address.` }</h1>
                                            <h1>{ `${ txsError }` }</h1>
                                        </div>
                                ) }
                                { txs && txs[0].length > 0 && (
                                        <div className="mt-10">
                                            <AddressDetailInfo
                                                    balance={ balances?.find((balance) => balance.symbol === Constants.ZENSymbol) ? BigInt(balances!.find((balance) => balance.symbol === Constants.ZENSymbol)!.balance) : BigInt(0) }
                                                    precision={ Constants.ZENPrecision }
                                                    totalTransactions={ totalTransactions ?? "0" }/>
                                        </div>
                                ) }
                                { (txs && txs[0].length > 0) && (
                                        <div className="mt-12 grid grid-cols-2 space-x-8">
                                            <div className="col-span-1 max-h-80">
                                                <p className="font-bold text-white text-2xl mb-4 mr-4">Token
                                                    Balances</p>
                                                
                                                <ListContainer
                                                        titleSection={ <></> }
                                                        elements={ ["Token Symbol", "Balance"] }
                                                        elementsConfig={ ["4/6", "2/6"] }
                                                        isLoading={ balanceIsLoading }
                                                        isEmpty={ balances ? balances.length === 0 : true }
                                                        customClass="max-h-full overflow-y-scroll"
                                                        tableContent={
                                                            balances?.map((b: any, index: number) => {
                                                                return (
                                                                        <tr key={ b.uuid }
                                                                            className={ (index === balances.length - 1 ? "" : "border-b").concat(" border-Gray_text h-12") }>
                                                                            <td className="text-sm pr-8">
                                                                                { b.symbol === Constants.ZENSymbol ? (
                                                                                        <span className="text-white">
                                                                            { b.symbol }
                                                                        </span>
                                                                                ) : (
                                                                                        <Link
                                                                                                className="hover:underline"
                                                                                                to={ URLProvider.URL_TOKEN_DETAIL.replace(
                                                                                                        ":tokenId",
                                                                                                        b.uuid
                                                                                                ) }
                                                                                        >
                                                                                            { b.symbol }
                                                                                        </Link>
                                                                                ) }

                                                                                </td>
                                                                                <td className="text-sm text-right text-white whitespace-nowrap truncate">{ bigNumberFormat(b.balance, b.precision) }</td>
                                                                            </tr>
                                                                    )
                                                                }) ?? <></>
                                                        }
                                                />
                                                        
                                            </div>
                                            { nftsEnabled && (
                                                <div className="col-span-1 max-h-80">
                                                    <p className="font-bold text-white text-2xl mb-4 mr-4">NFTs</p>
                                                    <ListContainer
                                                            titleSection={ <></> }
                                                            elements={ ["Collection", "Token ID"] }
                                                            elementsConfig={ ["4/6", "2/6"] }
                                                            isLoading={ nftsIsLoading }
                                                            isEmpty={ nfts ? nfts.length === 0 : true }
                                                            customClass="max-h-full overflow-y-scroll"
                                                            tableContent={
                                                                nfts?.map((b: any, index: number) => {
                                                                    return (
                                                                            <tr key={ b.uuid }
                                                                                className={ (index === nfts.length - 1 ? "" : "border-b").concat(" border-Gray_text h-12") }>
                                                                                <td className="text-sm pr-8 text-white whitespace-nowrap truncate">{ b.collectionName }</td>
                                                                                <td className="text-sm text-right truncate">
                                                                                    <Link
                                                                                            className="hover:underline"
                                                                                            to={ URLProvider.URL_NFT_DETAIL.replace(
                                                                                                    ":collectionId",
                                                                                                    b.collectionUuid
                                                                                            ).replace(":serialNumber", b.serialNumber) }
                                                                                    >
                                                                                        { `${ b.collectionSymbol } #${ splitStringMiddle(b.serialNumber, 20) }` }
                                                                                    </Link>
                                                                                </td>
                                                                            </tr>
                                                                    )
                                                                }) ?? <></>
                                                            }
                                                    />
                                                </div>
                                            ) }
                                        </div>
                                ) }
                            </div>
                        </div>
                        { txs && txs[0].length > 0 && (
                                <div className="px-8 sm:pb-8 space-y-16 mt-20">
                                    <ListContainer
                                            titleSection={ titleSection() }
                                            elements={ ["Transaction ID", "Age", "Type Name", "Amount", "Fee"] }
                                            elementsConfig={ ["1/5", "1/5", "1/5", "1/5", "1/5"] }
                                            isLoading={ txsIsLoading }
                                            isEmpty={ false }
                                            tableContent={
                                                txs ? txs[0].map((b: any, index: number) => {
                                                    return (
                                                            <tr key={ b.txid }
                                                                className={ (index === txs[0].length - 1 ? "" : "border-b").concat(" border-Gray_text h-12") }>
                                                                <td className="text-sm pr-8 truncate">
                                                                    <Link
                                                                            className="hover:underline"
                                                                            to={ URLProvider.URL_TRANSACTION_DETAIL.replace(
                                                                                    ":txId",
                                                                                    b.txid
                                                                            ) }
                                                                    >
                                                                        { b.txid }
                                                                    </Link>
                                                                </td>

                                                                <td className="text-sm pr-8 text-white whitespace-nowrap truncate">{ getElapsedTime(b.blockTime!) }</td>
                                                                <td className="text-sm pr-8 text-white truncate"
                                                                    title={ b.typeName }>{ b.typeName }</td>
                                                                { amountFormatted(b) }
                                                                <td className="text-sm text-white text-right truncate">
                                                            <span className="inline">{ b.fee || b.fee === 0
                                                                    ? bigNumberFormat(b.fee, Constants.ZENPrecision)
                                                                    : "-" } </span>
                                                                    <span className="inline font-bold">ZEN</span>
                                                                </td>
                                                            </tr>
                                                    )
                                                }) : undefined
                                            }
                                    >
                                    </ListContainer>
                                </div>
                        ) }
                    </div>
                </main>
            </div>
    )
}

export default AddressDetail
