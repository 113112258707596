import React, { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"
import TransactionsList from "../components/TransactionsList"
import { getNFTCollections, getNFTsByCollection, getTransactionByNFT } from "../network/APIRepository"
import { SCAPI } from "../network/explorer_types/SCAPI"
import { Constants } from "../utils/Constants"
import TokenMintSpinner from "../templates/TokenMintSpinner";
import { URLProvider } from "../utils/URLProvider";
import axios from "axios";
import NoImageIcon from "../assets/no-image-placeholder.png";
import { getIPFSFinalURL } from "../utils/Utils"
import ListHeader from "../components/ListHeader/ListHeader";
import InfoBlock, { InfoType } from "../components/InfoBlock/InfoBlock";

const NFTDetail: React.FC = () => {
    const params = useParams()
    const {collectionId, serialNumber} = params

    const [symbol, setSymbol] = useState<string | undefined>(undefined)
    const [currentPage, setCurrentPage] = useState<number>(0)
    const [totalPages, setTotalPages] = useState<number | undefined>(undefined)

    const [baseURI, setBaseURI] = useState<string | undefined>(undefined)
    const [ipfsURI, setIpfsURI] = useState<string | undefined>(undefined)
    const [imageURI, setImageURI] = useState<string | undefined>(undefined)
    const [metadata, setMetadata] = useState<any[] | undefined>(undefined)
    const [imageIsLoading, setImageIsLoading] = useState(true);
    const [imageError, setImageError] = useState(false);

    const {
        data: collection,
        error: collectionError,
        isLoading: collectionIsLoading
    } = useQuery<[Array<SCAPI.TokenBoxType>, number] | void>(["getCollection", collectionId], () => getNFTCollections(currentPage, undefined, collectionId))

    const {
        data: nft,
        error: nftError,
        isLoading: nftIsLoading
    } = useQuery<[Array<SCAPI.TokenNonFungibleBoxType>, number] | void>(["getNFTsByCollection", collectionId, serialNumber], () => getNFTsByCollection(0, collectionId, serialNumber))

    const {
        data: txs,
        error: txsError,
        isLoading: txsIsLoading
    } = useQuery<any>(["getTransactionsByNFT", collectionId, serialNumber, currentPage], () => getTransactionByNFT(currentPage, collectionId!, serialNumber!))

    const {
        data,
        error: metadataError,
        isLoading: metadataIsLoading
    } = useQuery<[any[], number] | void>(
            ["getNFTMetadata", baseURI, nft ? nft[0][0].serialNumber : ""],
            async () => {
                const axiosInstance = axios.create();
                await axiosInstance
                        .get(baseURI!, {})
                        .then((result) => {
                            if (result.data.attributes) {
                                setMetadata(result.data.attributes)
                            }
                            if (result.data.image && result.data.image !== "") {
                                const finalURI = getIPFSFinalURL(result.data.image)
                                setImageURI(finalURI);
                                setIpfsURI(finalURI)
                            } else {
                                setImageError(true)
                                setImageIsLoading(false);
                            }
                        })
                        .catch((e) => {
                            setImageError(true)
                            setImageIsLoading(false);
                        });
            }
            , {enabled: baseURI !== undefined && nft !== undefined && nft[0].length > 0}
    )

    useEffect(() => {
        if (collection && collection[0].length > 0) {
            setSymbol(collection[0][0].symbol)
        }
        if (txs) {
            setTotalPages(txs[1])
        }
    }, [txs])

    useEffect(() => {
        if (collection && collection[0].length > 0 && nft && nft[0].length > 0 && !baseURI) {
            setBaseURI(`${ getIPFSFinalURL(collection[0][0].baseURI) }${ nft[0][0].serialNumber }`)
        }
    }, [collection, nft])

    const metadataItem = (info: any) => {
        return (
                <div className="border border-white grid px-3 py-2">
                    <p className="text-Gray_text">{ info.trait_type }</p>
                    <p className="text-white font-bold truncate" title={ info.value }>{ info.value }</p>
                </div>
        )
    }

    const titleSection = () => {
        return (
                <ListHeader
                        title="Previous Transactions"
                        currentPage={ currentPage + 1 }
                        totalPages={ txs ? txs[1] === 0 ? 1 : txs[1] : undefined }
                        hasPreviousPage={ currentPage > 0 }
                        onPreviousClick={ () => {
                            setCurrentPage(currentPage - 1)
                        } }
                        onFirstClick={ () => {
                            setCurrentPage(0)
                        } }
                        onLastClick={ () => {
                            if ((totalPages && currentPage === totalPages - 1) ||
                                    totalPages === 0) {
                                return
                            }

                            setCurrentPage(totalPages! - 1)
                        } }
                        hasNextPage={ totalPages ? totalPages > currentPage + 1 : false }
                        onNextClick={ () => {
                            setCurrentPage(currentPage + 1)
                        } }/>
        )
    }

    const infoContent = (collection: any, nft: SCAPI.TokenNonFungibleBoxType) => {
        let output = [
            {
                title: "Collection",
                value: {
                    text: collection.name,
                    link: URLProvider.URL_COLLECTION_DETAIL.replace(":collectionId", collectionId as string)
                },
                type: InfoType.link
            },
            {
                title: "Collection UUID",
                value: collection.uuid,
                type: InfoType.text
            },
            {
                title: "Token ID",
                value: nft.serialNumber,
                type: InfoType.text
            },
            {
                title: "Owner",
                value: {
                    text: (nft.proposition as any).ownerProposition.publicKey,
                    link: URLProvider.URL_ADDRESS_DETAIL.replace(":address", (nft.proposition as any).ownerProposition.publicKey)
                },
                type: InfoType.link
            },
            {
                title: "Creator",
                value: {
                    text: (nft.proposition as any).publisherProposition.publicKey,
                    link: URLProvider.URL_ADDRESS_DETAIL.replace(":address", (nft.proposition as any).publisherProposition.publicKey)
                },
                type: InfoType.link
            },
        ]

        if (ipfsURI) {
            output.push(
                    {
                        title: "IPFS",
                        value: {
                            text: "Link",
                            external: true,
                            newTab: true,
                            link: ipfsURI
                        },
                        type: InfoType.link
                    },
            )
        }

        return output
    }

    const metadataSection = (properties: any[]) => {
        return (
                <div className={ `grid grid-cols-4` }>
                    {
                        properties.map((item, i) => {
                            return (
                                    <div className={ `${ i < 4 ? "mt-0" : "mt-4" } ${ i % 4 === 0 ? "" : "ml-4" }` }>
                                        { metadataItem(item) }
                                    </div>

                            )
                        })
                    }
                </div>
        )
    }

    return (
            <div>
                <main>
                    <div className="max-w-screen-xl ml-auto mr-auto xl:grid">
                        <div className="px-8 sm:pb-8 space-y-16 mt-20">
                            <>
                                { (collectionIsLoading || nftIsLoading) && (
                                        <div className="w-20 mx-auto">
                                            <TokenMintSpinner/>
                                        </div>
                                ) }
                                { collection && collection[0].length === 0 && nft && nft[0].length === 0 && (
                                        <div
                                                className="flex justify-center items-center text-2xl text-white my-40 font-bold">
                                            <h1>
                                                Sorry, we couldn&apos;t find the NFT you&apos;re looking for.
                                            </h1>
                                        </div>
                                ) }
                                { collection && collection[0].length > 0 && nft && nft[0].length > 0 && (
                                        <div className="grid grid-cols-5 space-x-10">
                                            <div className="col-span-2">
                                                <>
                                                    { imageURI && (
                                                            <img className={ `object-contain aspect-square ${ imageIsLoading ? "hidden" : "block" }` }
                                                                 onLoad={ () => {
                                                                     setImageIsLoading(false);
                                                                 } }
                                                                 onError={ () => {
                                                                     setImageIsLoading(false);
                                                                 } }
                                                                 src={ imageURI }/>
                                                    ) }
                                                    { (metadataError || imageError) && (
                                                            <img className="aspect-square"
                                                                 src={ NoImageIcon }/>
                                                    ) }
                                                    { (metadataIsLoading || imageIsLoading) && (
                                                            <div className="aspect-square p-36">
                                                                <TokenMintSpinner/>
                                                            </div>
                                                    ) }
                                                </>
                                            </div>
                                            <div className="col-span-3">
                                                <p className="font-bold text-white text-[34px] mb-1 mr-6 truncate">{ `${ collection[0][0].symbol } #${ nft[0][0].serialNumber }` }</p>
                                                <div className="mt-4">
                                                    <InfoBlock fields={ infoContent(collection[0][0], nft[0][0]) }/>
                                                </div>

                                                { metadata && metadata.length > 0 && (
                                                        <div className="mt-8">
                                                            <p className="text-Gray_text text-base mb-2">Properties:</p>
                                                            { metadataSection(metadata ?? []) }
                                                        </div>
                                                ) }
                                            </div>
                                        </div>
                                ) }
                            </>
                        </div>

                        { txs && txs[0].length > 0 && (
                                <div className="px-8 sm:pb-8 space-y-16 mt-12">
                                    <TransactionsList isLoading={ txsIsLoading }
                                                      isEmpty={ txs && txs[0].length === 0 }
                                                      symbol={ symbol }
                                                      precision={ collection ? collection[0][0].precision : 0 }
                                                      titleSection={ titleSection() }
                                                      filteredTxs={ txs ? txs[0] : undefined }/>
                                </div>
                        ) }
                    </div>
                </main>
            </div>
    )
}

export default NFTDetail
