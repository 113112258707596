import BigNumber from "bignumber.js"
import dateformat from "dateformat"
import { SCAPI } from "../network/explorer_types/SCAPI"
import { TransactionJSON } from "../network/explorer_types/Transaction"
import { Constants } from "./Constants"
import SidechainTransactionsTypes = SCAPI.SidechainTransactionsTypes
import TokenChainTransactionTypes = SCAPI.TokenChainTransactionTypes

export enum DateFormat {
    mmmddyyyyhhMMssTT = "UTC:mmm dd, yyyy hh:MM:ss TT",
    yyyymmdd_UTC = "UTC:yyyy-mm-dd",
    yyyymmdd = "yyyy-mm-dd",
}

export function getFormattedDate(date?: Date, format?: DateFormat): string {
    if (date) {
        return dateformat(date, format ?? DateFormat.mmmddyyyyhhMMssTT)
    }

    return ""
}

export function getDateFromString(date: string): Date {
    let parsed = new Date(Date.parse(date))
    parsed.setUTCHours(0, parsed.getTimezoneOffset(), 0, 0)
    return parsed
}

export function getPreviousDate(date: Date): string {
    const tempDate = new Date(date)
    tempDate.setDate(date.getDate() - 1)
    return dateformat(tempDate, DateFormat.yyyymmdd, true)
}

export function getNextDate(date: Date): string {
    const tempDate = new Date(date)
    tempDate.setDate(date.getDate() + 1)
    return dateformat(tempDate, DateFormat.yyyymmdd, true)
}

export function getNextDateFor(date: Date): Date {
    const tempDate = new Date(date)
    tempDate.setDate(date.getDate() + 1)
    return tempDate
}

export function isHash(input: string): boolean {
    return /^[a-z0-9]+$/i.test(input) && input.length === 64
}

export function splitStringMiddle(input: string, maxCharacters: number): string {
    // There's no need to cut
    if (maxCharacters >= input.length) {
        return input
    }

    let beginning = input.substring(0, input.length / 2)
    let end = input.substring(input.length / 2, input.length)
    while (beginning.length + end.length + 3 > maxCharacters) {
        beginning = beginning.substring(0, beginning.length - 1)
        end = end.substring(1, end.length)
    }

    return beginning.concat("...").concat(end)
}

export function getElapsedTime(date: Date): string {
    const startTime = new Date(date)
    const endTime = new Date()
    let timeDiff = (endTime.getTime() - startTime.getTime()) / 1000
    timeDiff = Math.floor(timeDiff / 60)

    const minutes = timeDiff % 60
    const minutesAsString =
        minutes < 10 ? "0".concat(minutes.toString()) : minutes.toString()
    timeDiff = Math.floor(timeDiff / 60)

    const hours = timeDiff % 24
    timeDiff = Math.floor(timeDiff / 24)

    const days = timeDiff
    const totalHours = hours + days * 24
    const totalHoursAsString =
        totalHours < 10
            ? "0".concat(totalHours.toString())
            : totalHours.toString()

    if (totalHoursAsString === "00") {
        return minutesAsString.concat(" minutes ago")
    }

    return totalHoursAsString
        .concat(" hours and ")
        .concat(minutesAsString.toString())
        .concat(" minutes ago")
}

export function isToday(someDate: Date): boolean {
    const today = new Date()
    return (
        someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear()
    )
}

export function isAfterToday(someDate: Date): boolean {
    const today = new Date()
    const sameYear = someDate.getFullYear() === today.getFullYear()
    const sameMonth = someDate.getMonth() === today.getMonth()
    return (
        (
            someDate.getDate() > today.getDate() &&
            sameMonth &&
            sameYear
        ) || (
            someDate.getMonth() > today.getMonth() &&
            sameYear
        ) || (
            someDate.getFullYear() > today.getFullYear()
        )
    )
}

export const delay = (ms: any) => new Promise((res) => setTimeout(res, ms))

export const txDateToShow = (currentDate: Date, b: SCAPI.Transaction) => {
    return isToday(currentDate!)
        ? getElapsedTime(b.blockTime!)
        : getFormattedDate(b.blockTime!, DateFormat.mmmddyyyyhhMMssTT)
}

export function boxesSums(tx: SCAPI.Transaction & TransactionJSON, inputSymbol?: string): { output: bigint | undefined, symbol: string } {
    let symbol = Constants.ZENSymbol
    const zeroBigInt: bigint = 0n
    if (inputSymbol) {
        symbol = inputSymbol
    } else {
        tx.vin?.forEach((box: SCAPI.Box) => {
            if ((box as any).symbol) {
                symbol = (box as SCAPI.TokenBoxType).symbol
            }
        })
    }

    let voutSummables: Array<BigNumber>
    switch (tx.typeName) {
        case SidechainTransactionsTypes.SidechainCoreTransaction:
            const vinAddresses = tx.vin?.filter((box: SCAPI.Box) => {
                return box.typeName === SCAPI.SidechainBoxTypes.ZenBox
            }).flatMap((box: SCAPI.Box) => {
                return box.proposition?.publicKey
            }) ?? []

            voutSummables =
                tx.vout
                    ?.filter((box: SCAPI.Box) => {
                        return (
                            box.typeName === SCAPI.SidechainBoxTypes.WithdrawalRequestBox ||
                            (box.typeName === SCAPI.SidechainBoxTypes.ZenBox &&
                                !vinAddresses.includes(box.proposition?.publicKey))
                        )
                    })
                    .flatMap((box: any) => {
                        const boxFormatted = box as SCAPI.Box
                        return new BigNumber(boxFormatted.value as any)
                    }) ?? []
            break
        case SidechainTransactionsTypes.MC2SCAggregatedTransaction:
        case SidechainTransactionsTypes.FeePaymentsTransaction:
            voutSummables = tx.vout?.filter((box: SCAPI.Box) => {
                return box.typeName === SCAPI.SidechainBoxTypes.ZenBox
            }).flatMap((box: any) => {
                const boxFormatted = box as SCAPI.Box
                return new BigNumber(boxFormatted.value as any)
            }) ?? []
            break
        case TokenChainTransactionTypes.TokenFungibleTransferTransaction:
            const addresses = tx.vin?.filter((box: SCAPI.Box) => {
                return box.typeName === SCAPI.TokenChainBoxTypes.TokenFungible
            }).flatMap((box: SCAPI.Box) => {
                return box.proposition?.publicKey
            }) ?? []

            voutSummables = tx.vout?.filter((box: SCAPI.Box) => {
                return box.typeName === SCAPI.TokenChainBoxTypes.TokenFungible &&
                    !addresses.includes(box.proposition?.publicKey)
            }).flatMap((box: any) => {
                const boxFormatted = box as SCAPI.TokenFungibleBoxType
                return new BigNumber(boxFormatted.tokenValue as any)
            }) ?? []
            break
        case TokenChainTransactionTypes.TokenNonFungibleMint:
        case TokenChainTransactionTypes.TokenNonFungibleTransfer:
            voutSummables = tx.vout?.filter((box: SCAPI.Box) => {
                return box.typeName === SCAPI.TokenChainBoxTypes.TokenNonFungible
            }).flatMap((box: any) => {
                return new BigNumber(1)
            }) ?? []
            break
        default:
            voutSummables = tx.vout?.filter((box: SCAPI.Box) => {
                return box.typeName === SCAPI.TokenChainBoxTypes.TokenFungible
            }).flatMap((box: any) => {
                const boxFormatted = box as SCAPI.TokenFungibleBoxType
                return new BigNumber(boxFormatted.tokenValue as any)
            }) ?? []
    }

    const vinSummables: Array<BigNumber> = tx.vin?.filter((box: SCAPI.Box) => {
        return box.typeName === SCAPI.TokenChainBoxTypes.TokenFungible
    }).flatMap((box: any) => {
        const boxFormatted = box as SCAPI.TokenFungibleBoxType
        return new BigNumber(boxFormatted.tokenValue as any)
    }) ?? []

    const vinSum = vinSummables.reduce((a: BigNumber, b: BigNumber) => a.plus(b), new BigNumber(zeroBigInt as any))
    const voutSum = voutSummables.reduce((a: BigNumber, b: BigNumber) => a.plus(b), new BigNumber(zeroBigInt as any))

    let outputSubs
    switch (tx.typeName) {
        case SCAPI.TokenChainTransactionTypes.TokenFungibleBurn:
            outputSubs = vinSum.minus(voutSum)
            break
        case SCAPI.TokenChainTransactionTypes.TokenFungibleMintTransaction:
            outputSubs = voutSum.minus(vinSum)
            break
        case SCAPI.TokenChainTransactionTypes.TokenTypeDeclareTransaction:
            outputSubs = undefined
            break
        default:
            outputSubs = voutSum
    }

    return { output: outputSubs?.toString() as any as bigint ?? zeroBigInt, symbol: symbol }
}

export function bigNumberFormat(input: any, precision: number): string {
    if (input === undefined) {
        return "-"
    }
    const inputBigNumber = new BigNumber(input as any)
    return inputBigNumber.dividedBy(10 ** precision).toFormat(undefined)
}

export function maxSupplyDisplay(maxSupply: SCAPI.TokenBoxType["maxSupply"], precision: SCAPI.TokenBoxType["precision"]) {
    return maxSupply.toString() === "0" ? "Unlimited" : bigNumberFormat(maxSupply, precision)
}

export function getIPFSFinalURL(ipfsURI: string): string {
    return ipfsURI.substr(0, 7) === "ipfs://"
        ? ipfsURI.replace("ipfs://", "https://ipfs.io/ipfs/")
        : ipfsURI;
}