import React, { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { Link, useParams } from "react-router-dom"
import ListContainer from "../../components/ListContainer/ListContainer"
import ListHeader from "../../components/ListHeader/ListHeader"
import { getTokens } from "../../network/APIRepository"
import { SCAPI } from "../../network/explorer_types/SCAPI"
import { URLProvider } from "../../utils/URLProvider"
import { bigNumberFormat, maxSupplyDisplay } from "../../utils/Utils"

const Tokens: React.FC = () => {
    const params = useParams()
    const {tokenSearch} = params

    const [lastTokenSearch, setLastTokenSearch] = useState<string | undefined>(undefined)
    const [currentPage, setCurrentPage] = useState<number>(0)
    const [totalPages, setTotalPages] = useState<number | undefined>(undefined)
    const [totalTokens, setTotalTokens] = useState<string | undefined>(undefined)
    const {
        data: tokens,
        error,
        isLoading
    } = useQuery<any>(["getTokens", currentPage, tokenSearch], () => getTokens(tokenSearch ? tokenSearch as string : undefined, undefined, undefined, currentPage))

    useEffect(() => {
        if (tokenSearch !== lastTokenSearch) {
            setCurrentPage(0)
        }
        setLastTokenSearch(tokenSearch)
    }, [tokenSearch])

    useEffect(() => {
        if (tokens) {
            setTotalPages(tokens[1])
            if (currentPage + 1 === tokens[1]) {
                // If last page we calculate with the number of pages and last items in last page
                setTotalTokens(tokens[0].length + currentPage * 20)
            } else if (tokens[1] === 0) {
                setTotalTokens("0")
            } else {
                // Otherwise, we estimate from the number of pages
                const currentNumber = (Number(tokens[1]) - 1) * 20
                if (totalTokens) {
                    if (Number(totalTokens) < currentNumber) {
                        setTotalTokens(currentNumber.toString().concat("+"))
                    }
                } else {
                    setTotalTokens(currentNumber.toString().concat("+"))
                }
            }
        }

    }, [tokens])

    const titleSection = () => {
        return (
                <ListHeader
                        title="TokenMint Tokens"
                        secondaryTitle={ tokens ? `${ totalTokens } tokens found` : "" }
                        currentPage={ currentPage + 1 }
                        totalPages={ tokens ? tokens[1] === 0 ? 1 : tokens[1] : undefined }
                        hasPreviousPage={ currentPage > 0 }
                        onPreviousClick={ () => {
                            setCurrentPage(currentPage - 1)
                        } }
                        onFirstClick={ () => {
                            setCurrentPage(0)
                        } }
                        onLastClick={ () => {
                            if ((totalPages && currentPage === totalPages - 1) ||
                                    totalPages === 0) {
                                return
                            }

                            setCurrentPage(totalPages! - 1)
                        } }
                        hasNextPage={ totalPages ? totalPages > currentPage + 1 : false }
                        onNextClick={ () => {
                            setCurrentPage(currentPage + 1)
                        } }/>
        )
    }

    return (
            <div>
                <main>
                    <div className="max-w-screen-xl ml-auto mr-auto xl:grid">
                        <div className="px-8 sm:pb-8 space-y-16 mt-20">
                            <ListContainer
                                    titleSection={ titleSection() }
                                    elements={ ["Token", "UUID", "Circulating Supply", "Total Supply"] }
                                    elementsConfig={ ["1/4", "1/4", "1/4", "1/4"] }
                                    isLoading={ isLoading }
                                    isEmpty={ tokens ? tokens[0].length === 0 : false }
                                    tableContent={
                                        tokens ? tokens[0].map((b: SCAPI.TokenBoxType, index: number) => {
                                            return (
                                                    <tr key={ b.uuid }
                                                        data-testid={"token_row"}
                                                        className={ (index === tokens[0].length - 1 ? "" : "border-b").concat(" border-Gray_text h-12") }>
                                                        <td className="text-sm pr-8">
                                                            <Link
                                                                    className="hover:underline text-white hover:text-ZBF_green font-bold"
                                                                    to={ URLProvider.URL_TOKEN_DETAIL.replace(
                                                                            ":tokenId",
                                                                            b.uuid
                                                                    ) }
                                                            >
                                                                { b.symbol }
                                                            </Link>
                                                        </td>

                                                        <td className="text-sm pr-8 text-white truncate"
                                                            title={ b.uuid }>{ b.uuid }</td>
                                                        <td className="text-sm pr-8 text-white truncate"
                                                            title={ bigNumberFormat(b.currentSupply, b.precision) }>{ bigNumberFormat(b.currentSupply, b.precision) }</td>
                                                        <td className="text-sm text-right text-white truncate"
                                                            title={ maxSupplyDisplay(b.maxSupply, b.precision) }>{ maxSupplyDisplay(b.maxSupply, b.precision) }</td>
                                                    </tr>
                                            )
                                        }) : undefined
                                    }
                            >
                            </ListContainer>
                        </div>
                    </div>
                </main>
            </div>
    )
}

export default Tokens
