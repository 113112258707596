import React from "react"
import FooterSubheading from "./FooterSubheading"
import { Link } from "../../types/Link"

type LinksGroupProps = {
    title: string
    links: Link[]
}

const LinksGroup: React.FC<LinksGroupProps> = ({ title, links }) => {
    return (
        <div key={`link_${title}`}>
            <FooterSubheading>{ title }</FooterSubheading>
            <ul>
                { links.map((link) => {
                    return (
                        <li className="mb-2" key={`link_${title}_${link.label}`}>
                            <a
                                className="text-Gray_text hover:text-white"
                                href={ link.href }
                                target={ link.openNewTab ? "_blank" : "" }
                                rel="noopener noreferrer"
                            >
                                { link.label }
                            </a>
                        </li>
                    )
                }) }
            </ul>
        </div>
    )
}

export default LinksGroup
