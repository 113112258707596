import React from "react"

const NotFound = () => {
    return (
        <div className="flex justify-center items-center text-2xl text-white font-bold min-h-[calc(100vh-64px-48px)]">
            <h1>
                Sorry, we couldn&apos;t find the page you&apos;re looking for.
            </h1>
        </div>
    )
}

export default NotFound
