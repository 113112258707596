import React, { useEffect, useState } from "react"
import { Toaster } from "react-hot-toast"
import Modal from "react-modal"
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom"
import socketio from "socket.io-client"
import { APIURLProvider } from "./network/APIURLProvider"
import { Transaction } from "./network/explorer_types/Transaction"
import Home from "./pages/Homepage"
import NotFound from "./pages/404"
import AddressDetail from "./pages/AddressDetail"
import BlockDetail from "./pages/BlockDetail"
import Blocks from "./pages/Blocks/Blocks"
import Status from "./pages/Status/Status"
import TokenDetail from "./pages/TokenDetail/TokenDetail"
import Tokens from "./pages/Tokens/Tokens"
import TransactionDetail from "./pages/TransactionDetail"
import Transactions from "./pages/Transactions/Transactions"
import Layout from "./templates/Layout"
import { URLProvider } from "./utils/URLProvider"
import NFTCollections from "./pages/NFTCollections";
import NFTCollectionDetail from "./pages/NFTCollectionDetail";
import NFTDetail from "./pages/NFTDetail";
import Version from "./pages/Version"
import { useMatomo } from "@jonkoops/matomo-tracker-react"

function App() {
    const [tx, setTx] = useState<Transaction | undefined>(undefined)
    const [toastPosition, setToastPosition] = useState(0)
    Modal.setAppElement("#root")

    useEffect(() => {
        setToastPosition(Math.floor(window.innerHeight / 4))

        const socket = socketio(APIURLProvider.socketUrl, {transports: ["websocket"]})

        socket.on("connect", () => {
            socket.emit("subscribe", "inv")
        })
        socket.on("tx", (data: any) => {
            const txData = JSON.parse(data)
            setTx(txData)
        })
        return () => {
            socket.disconnect()
        }
    }, [])

    const { trackPageView } = useMatomo()
    const location = useLocation()

    useEffect(() => {
        trackPageView()
    }, [location, trackPageView])


    useEffect(() => {
        window.plausible =
            window.plausible ||
            function () {
                ;(window.plausible.q = window.plausible.q || []).push(arguments)
            }
    }, [])

    const nftsEnabled = process.env.REACT_APP_NFTs_ENABLED ? process.env.REACT_APP_NFTs_ENABLED === "true" : false

    return (
        <>
            <Toaster
                containerStyle={{ marginTop: `${toastPosition}px` }}
                containerClassName="text-2xl"
                toastOptions={{
                    duration: 3000,
                    style: {
                        maxWidth: "100%",
                        backgroundColor: "black",
                        color: "white",
                    },
                }}
            />

            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home tx={tx} />} />
                    <Route path={URLProvider.URL_TOKENS} element={<Tokens />} />
                    <Route path={URLProvider.URL_TOKENS_SEARCH} element={<Tokens />} />
                    <Route path={URLProvider.URL_TOKEN_DETAIL} element={<TokenDetail />} />
                    <Route path={URLProvider.URL_TRANSACTION_DETAIL} element={<TransactionDetail />} />
                    <Route path={URLProvider.URL_TRANSACTIONS} element={<Transactions />} />
                    <Route path={URLProvider.URL_TRANSACTIONS_DATE} element={<Transactions />} />
                    <Route path={URLProvider.URL_STATUS} element={<Status />} />
                    <Route path={URLProvider.URL_ADDRESS_DETAIL} element={<AddressDetail />} />
                    <Route path={URLProvider.URL_BLOCKS} element={<Blocks />} />
                    <Route path={URLProvider.URL_BLOCKS_DATE} element={<Blocks />} />
                    <Route path={URLProvider.URL_BLOCK_DETAIL} element={<BlockDetail />} />
                    {nftsEnabled && (
                        <>
                            <Route path={URLProvider.URL_NFT_COLLECTIONS} element={<NFTCollections />} />
                            <Route path={URLProvider.URL_NFT_COLLECTIONS_SEARCH} element={<NFTCollections />} />
                            <Route path={URLProvider.URL_COLLECTION_DETAIL} element={<NFTCollectionDetail />} />
                            <Route path={URLProvider.URL_NFT_DETAIL} element={<NFTDetail />} />
                        </>
                    )}
                    <Route path="*" element={<NotFound />} />
                </Route>
                <Route path={URLProvider.URL_VERSION} element={<Version />} />
            </Routes>
        </>
    )
}

export default App
