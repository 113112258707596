import React, { useCallback, useState } from "react"
import { toast } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import ReactTooltip from "react-tooltip"
import { useLocation, useNavigate } from "react-router-dom"
import { detectRoute, URLProvider } from "../../utils/URLProvider"
import { isHash } from "../../utils/Utils"
import NavLink from "../NavLink/NavLink"
import arrowCollapse from "../../assets/green_arrow_down.png"
import explorerLogo from "../../assets/explorer_logo_alpha.svg"
import i18next, { filteredLanguage, supportedLanguages } from "../../translations/translationsCommons"
import { getBlockTransactionOrAddress } from "../../network/APIRepository";

export enum SearchFilterType {
    allFilters = "allFilters",
    address = "address",
    token = "token",
    nftCollection = "nftCollection",
    block = "block",
    transaction = "transaction"
}

const searchTypeLabel = (type: SearchFilterType): string => {
    switch (type) {
        case SearchFilterType.allFilters:
            return "All Filters"
        case SearchFilterType.address:
            return "Address"
        case SearchFilterType.token:
            return "Token"
        case SearchFilterType.nftCollection:
            return "NFT Collection"
        case SearchFilterType.block:
            return "Block"
        case SearchFilterType.transaction:
            return "Transaction"
    }
}

const Header = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const route = detectRoute(location.pathname)

    const [searching, setSearching] = useState(false)
    const [languageSelectorEnabled] = useState(false)
    const [languagesOpen, setLanguagesOpen] = useState(false)
    const [currentFilter, setCurrentFilter] = useState<SearchFilterType>(SearchFilterType.allFilters)
    const [search, setSearch] = useState("")
    const {i18n} = useTranslation()
    const [dropdownMenuOpen, setDropdownMenuOpen] = useState(false)
    const bannerEnabled = process.env.REACT_APP_WARNING_BANNER_VISIBLE ? process.env.REACT_APP_WARNING_BANNER_VISIBLE === "true" : false

    const handleSwitchLanguage = useCallback(
            (lang: string) => {
                i18next
                        .changeLanguage(lang)
                        .then(() => {
                            navigate(location.pathname)
                        })
                        .catch((e: any) => console.log(e))
            },
            [i18next]
    )

    const innerLink = (boldPaths: string[], text: string, link?: string, linkId?: string) => {
        const isActive = boldPaths.includes(route)
        if (link) {
            return (
                    <span className={ "mx-6 h-full pt-4 font-bold text-base" }>
                    <NavLink
                            className={ (isActive ? "active" : "").concat(" routerNavLink whitespace-nowrap") }
                            to={ link }>
                            { text }
                        </NavLink>
                </span>
            )
        } else {
            return (
                    <span
                            className={ `${ linkId } hover:cursor-pointer mx-6 h-full pt-4 font-bold text-base routerNavLink whitespace-nowrap` }>
                    { text }
                </span>
            )
        }
    }

    const getAddress = () => {
        setSearching(false)
        setSearch("")
        navigate(
                URLProvider.URL_ADDRESS_DETAIL.replace(
                        ":address",
                        search
                )
        )
    }

    const getToken = () => {
        setSearching(false)
        setSearch("")
        navigate(
                URLProvider.URL_TOKEN_DETAIL.replace(
                        ":tokenId",
                        search
                )
        )
    }

    const getNFTCollectionDetail = () => {
        setSearching(false)
        setSearch("")
        navigate(
                URLProvider.URL_COLLECTION_DETAIL.replace(
                        ":collectionId",
                        search
                )
        )
    }

    const getTokenSearch = () => {
        setSearching(false)
        setSearch("")
        navigate(
                URLProvider.URL_TOKENS_SEARCH.replace(
                        ":tokenSearch",
                        search.toUpperCase()
                )
        )
    }

    const getNFTCollectionSearch = () => {
        setSearching(false)
        setSearch("")
        navigate(
                URLProvider.URL_NFT_COLLECTIONS_SEARCH.replace(
                        ":tokenSearch",
                        search.toUpperCase()
                )
        )
    }

    const getTransactionById = (searchField: string) => {
        setSearching(false)
        setSearch("")
        navigate(
                URLProvider.URL_TRANSACTION_DETAIL.replace(
                        ":txId",
                        searchField
                )
        )
    }

    const getBlock = (searchField: string) => {
        setSearching(false)
        setSearch("")
        navigate(
                URLProvider.URL_BLOCK_DETAIL.replace(
                        ":blockId",
                        searchField
                )
        )
    }

    function redirectToDestination(destination: SearchFilterType) {
        switch (destination) {
            case SearchFilterType.address:
                getAddress()
                break
            case SearchFilterType.token:
                isHash(search)
                        ? getToken()
                        : getTokenSearch()
                break
            case SearchFilterType.nftCollection:
                isHash(search)
                        ? getNFTCollectionDetail()
                        : getNFTCollectionSearch()
                break
            case SearchFilterType.transaction:
                getTransactionById(search)
                break
            case SearchFilterType.block:
                getBlock(search)
                break
        }
    }

    const onSearchSubmit = async () => {
        if (currentFilter === SearchFilterType.allFilters) {
            setSearching(true)
            await getBlockTransactionOrAddress(search)
                    .then((response) => {
                        if (response) {
                            redirectToDestination(response)
                        }
                    })
                    .catch((error) => {
                        toast(`No result was found`)
                    })
                    .finally(() => {
                        setSearching(false)
                        setSearch("")
                    })

        } else {
            redirectToDestination(currentFilter)
        }
    }

    const filtersDropdownMenu = () => {
        const options: SearchFilterType[] = nftsEnabled
                ? [SearchFilterType.allFilters, SearchFilterType.address, SearchFilterType.token, SearchFilterType.nftCollection, SearchFilterType.block, SearchFilterType.transaction]
                : [SearchFilterType.allFilters, SearchFilterType.address, SearchFilterType.token, SearchFilterType.block, SearchFilterType.transaction]

        return (
                <ul id="filters_menu"
                    data-testid={ "filters_menu" }
                    className={ `${ dropdownMenuOpen ? 'block' : '!hidden' } bg-Main_bckgrnd absolute z-50 ${ bannerEnabled ? "lg:top-[6.9em]" : "lg:top-[3.75em]" } -ml-[0.52em] mr-7 inline-grid w-40 border border-b` }>
                    { options.map((option: any) => {
                        return (
                                <li key={ `filter_menu_${ option.toString() }` }>
                                    <button
                                            type="button"
                                            className="menu-option mx-auto text-Content_gray hover:text-white bg-Main_bckgrnd selected p-3 w-full text-left font-bold"
                                            onClick={ () => {
                                                setCurrentFilter(option)
                                                setDropdownMenuOpen(false)
                                            } }
                                    >
                                        <span className="m-auto">
                                            { searchTypeLabel(option) }
                                        </span>
                                    </button>
                                </li>
                        )
                    }) }
                </ul>
        )
    }

    const tokenmintDropdownMenu = () => {
        const options: any[] = [
            {filterType: "blocks", label: "Blocks"},
            {filterType: "transactions", label: "Transactions"}
        ]
        return (
                <ul id="tokenmint_link_menu"
                    className={ `bg-Main_bckgrnd absolute z-50 ${ bannerEnabled ? "lg:top-[6.9em]" : "lg:top-[4.3em]" } mr-20 inline-grid w-40 border border-b` }>
                    { options.map((option: any) => {
                        return (
                                <li key={ `link_button_${ option.toString() }` }>
                                    <button
                                            type="button"
                                            className="menu-option mx-auto text-Content_gray hover:text-white bg-Main_bckgrnd selected p-3 w-full text-left font-bold"
                                            onClick={ () => {
                                                switch (option.filterType) {
                                                    case "blocks":
                                                        navigate(URLProvider.URL_BLOCKS)
                                                        return
                                                    case "transactions":
                                                        navigate(URLProvider.URL_TRANSACTIONS)
                                                        return
                                                    default:
                                                        break
                                                }
                                            } }
                                    >
                            <span className="m-auto">
                                { option.label }
                            </span>
                                    </button>
                                </li>
                        )
                    }) }
                </ul>
        )
    }

    const nftsEnabled = process.env.REACT_APP_NFTs_ENABLED ? process.env.REACT_APP_NFTs_ENABLED === "true" : false

    return (
            <header className="max-w-screen-xl mx-auto lg:h-20 h-40 px-8 bg-">
                <div className="lg:flex lg:justify-between grid min-w-full h-full">
                    <div className="flex h-20 lg:justify-around justify-between items-center lg:mr-7 mr-12">
                        <NavLink className="mr-6" to="/">
                            <img
                                    className="h-8 object-contain"
                                    src={ explorerLogo }
                                    alt="logo"
                            />
                        </NavLink>
                        <div className="lg:w-10/12 w-full flex max-w-[1200px]">
                            <button
                                    className="filters_menu_button flex pl-2 pr-4 items-center border-l border-t border-b border-white bg-Main_bckgrnd"
                                    onMouseOver={ () => setDropdownMenuOpen(true) }>
                            <span
                                    data-testid={ "filters_menu_current" }
                                    className="w-24 text-sm text-white font-bold whitespace-nowrap">{ searchTypeLabel(currentFilter) }</span>
                                { filtersDropdownMenu() }
                                <img
                                        id="filter_arrow"
                                        className="w-3 my-auto ml-1 mr-1"
                                        src={ arrowCollapse }/>
                            </button>
                            <input
                                    disabled={ searching }
                                    className="border text-sm text-Content_gray p-2 py-3 pl-4 mr-5 border-white w-full bg-Main_bckgrnd min-w-[22rem]"
                                    type="text"
                                    value={ search }
                                    onKeyDown={ (event) => {
                                        if (event.key === "Enter") {
                                            onSearchSubmit()
                                        }
                                    } }
                                    onChange={ (event) => {
                                        setSearch(event.target.value)
                                    } }
                                    placeholder={ nftsEnabled ? "Search by Address / Transaction / Block / Token / NFT" : "Search by Address / Transaction / Block / Token" }
                            />
                            <div
                                    className={ `${ searching ? "visible" : "invisible" } relative right-14 top-2 loader ease-linear rounded-full border-4 h-6 w-6 min-w-[1.5rem] min-h-[1.5rem] animate-spin border-t-ZBF_green border-gray-separators` }/>
                        </div>
                    </div>
                    <div className="flex lg:justify-around h-20 items-center py-2">
                        {/* Some of these buttons will have a dropdown menu */ }
                        { innerLink(
                                [URLProvider.URL_STATUS],
                                "Status",
                                URLProvider.URL_STATUS
                        ) }
                        { innerLink(
                                [URLProvider.URL_BLOCKS, URLProvider.URL_BLOCKS_DATE],
                                "TokenMint Chain",
                                undefined,
                                "tokenmint_link"
                        ) }
                        { tokenmintDropdownMenu() }
                        { innerLink(
                                [URLProvider.URL_TOKENS],
                                "Tokens",
                                URLProvider.URL_TOKENS
                        ) }
                        { !nftsEnabled && (
                                <button
                                        className={ `${ [URLProvider.URL_NFT_COLLECTIONS].includes(route) ? "active" : "" } mx-6 pb-2 h-full font-bold text-base routerNavLink` }
                                        onClick={ () => {

                                        } }>
                                    <ReactTooltip
                                            id="description"
                                            effect="solid"
                                            multiline
                                    />
                                    <span
                                            className="font-bold"
                                            data-tip="Coming soon"
                                            data-for="description"
                                            data-background-color="#041742"
                                    >
                            NFT
                        </span>
                                </button>

                        ) }
                        { nftsEnabled && (
                                innerLink(
                                        [URLProvider.URL_NFT_COLLECTIONS],
                                        "NFTs",
                                        URLProvider.URL_NFT_COLLECTIONS
                                )
                        ) }
                        { languageSelectorEnabled && (
                                <button
                                        type="button"
                                        className="float-right text-white bg-[#0E9DE5] p-3 ml-4 lg:w-2/12 md:w-1/12 w-2/12"
                                        onClick={ () => {
                                            setLanguagesOpen(!languagesOpen)
                                        } }
                                >
                                    { supportedLanguages.map((lang) => {
                                        if (
                                                lang.id === filteredLanguage(i18n.language)
                                        ) {
                                            return (
                                                    <span className="m-auto">
                                            { lang.lang }
                                        </span>
                                            )
                                        }
                                        return <></>
                                    }) }
                                </button>
                        ) }
                        { languagesOpen && languageSelectorEnabled && (
                                <ul className="absolute right-1 lg:top-14 mr-7 inline-grid w-20">
                                    { supportedLanguages.map((lang) => {
                                        if (
                                                lang.id !== filteredLanguage(i18n.language)
                                        ) {
                                            return (
                                                    <li>
                                                        <button
                                                                type="button"
                                                                className="mx-auto -mb-[0.12em] float-right bg-[#ffffff] border-solid border-[#c8cad0] border-2 selected p-3 w-full text-left hover:bg-[#EEF0F3]"
                                                                onClick={ () => {
                                                                    handleSwitchLanguage(
                                                                            lang.id
                                                                    )
                                                                } }
                                                        >
                                                <span className="m-auto">
                                                    { lang.lang }
                                                </span>
                                                        </button>
                                                    </li>
                                            )
                                        }
                                        return <></>
                                    }) }
                                </ul>
                        ) }
                    </div>
                </div>
            </header>
    )
}

export default Header